import React from 'react';
import classnames from 'classnames';
import { IMessage } from '../MessageList.types';
import { StyledMessage } from './Message.styles';

export interface MessageProps<T> {
  className?: string;
  message: IMessage<T>;
  buttonViewRenderer?: (originalEntity: T) => React.ReactElement;
}

export const Message = <T,>({ className, message, buttonViewRenderer }: MessageProps<T>): JSX.Element => (
  <StyledMessage className={classnames('message', className)}>
    {(message.icon || message.title || message.subtitle || buttonViewRenderer) && (
      <div className="message__header">
        <div className="message__icon">{message.icon}</div>
        <div className="message__header-text">
          <div className="message__title" title={message.title}>
            {message.title}
          </div>
          <div className="message__subtitle">{message.subtitle}</div>
        </div>
        {buttonViewRenderer && (
          <div className="message__header-buttons">{buttonViewRenderer(message.originalEntity)}</div>
        )}
      </div>
    )}

    <div className="message__description">{message.description}</div>
  </StyledMessage>
);

// For a list of common MIME types see:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

export enum MimeTypeCategory {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
  PRESENTATION = 'PRESENTATION',
  SPREADSHEET = 'SPREADSHEET',
  VIDEO = 'VIDEO',
}

export type IMimeType =
  | MimeTypeAudio
  | MimeTypeDocument
  | MimeTypeImage
  | MimeTypeOther
  | MimeTypePresentation
  | MimeTypeSpreadsheet
  | MimeTypeVideo;

export enum MimeTypeAudio {
  // Audio
  '3GPP_AUDIO' = 'audio/3gpp',
  '3GPP2_AUDIO' = 'audio/3gpp2',
  AAC = 'audio/aac',
  MIDI = 'audio/x-midi',
  MP3 = 'audio/mpeg',
  OGG_AUDIO = 'audio/ogg',
  OPUS = 'audio/opus',
  WAV = 'audio/wav',
  WEBM_AUDIO = 'audio/webm',
}

export enum MimeTypeDocument {
  // Document
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ODT = 'application/vnd.oasis.opendocument.text',
  PDF = 'application/pdf',
  RTF = 'application/rtf',
  TXT = 'text/plain',
}

export enum MimeTypeImage {
  // Image
  BMP = 'image/bmp',
  GIF = 'image/gif',
  ICO = 'image/vnd.microsoft.icon',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  TIFF = 'image/tiff',
  WEBP = 'image/webp',
}

export enum MimeTypePresentation {
  // Presentation
  ODP = 'application/vnd.oasis.opendocument.presentation',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum MimeTypeSpreadsheet {
  // Spreadsheet
  CSV = 'text/csv',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum MimeTypeVideo {
  // Video
  '3GPP_VIDEO' = 'video/3gpp',
  '3GPP2_VIDEO' = 'video/3gpp2',
  AVI = 'video/x-msvideo',
  MP4 = 'video/mp4',
  MPEG = 'video/mpeg',
  OGG_VIDEO = 'video/ogg',
  WEBM_VIDEO = 'video/webm',
}

export enum MimeTypeOther {
  // Other
  '7ZIP' = 'application/x-7z-compressed',
  BINARY = 'application/octet-stream',
  BZIP = 'application/x-bzip',
  BZIP2 = 'application/x-bzip2',
  CSS = 'text/css',
  EOT = 'application/vnd.ms-fontobject',
  EPUB = 'application/epub+zip',
  GZIP = 'application/gzip',
  HTML = 'text/html',
  ICAL = 'text/calendar',
  JS = 'text/javascript',
  JSON = 'application/json',
  OTF = 'font/otf',
  RAR = 'application/vnd.rar',
  TAR = 'application/x-tar',
  TTF = 'font/ttf',
  VSD = 'application/vnd.visio',
  WOFF = 'font/woff',
  WOFF2 = 'font/woff2',
  XML = 'application/xml',
  ZIP = 'application/zip',
}

import styled from 'styled-components';
import { Checkbox } from 'antd';

export const StyledLegacyCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    align-items: center;
  }

  // Checkbox normal - unchecked
  .ant-checkbox {
    top: 0;

    .ant-checkbox-inner {
      border: 2px solid ${(props): string => props.theme.colors.black};
      border-radius: 0;
      width: 24px;
      height: 24px;

      &:after {
        width: 7px;
        height: 13px;
        transform: rotate(45deg) scale(1.2) translate(-55%, -55%);
      }
    }
  }

  // Checkbox checked
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${(props): string => props.theme.colors.black};
    }

    &:after {
      border-color: ${(props): string => props.theme.colors.black};
    }
  }

  // White mode
  &.white-mode {
    .ant-checkbox-inner {
      background-color: ${(props): string => props.theme.colors.white};

      &:after {
        border-color: ${(props): string => props.theme.colors.black};
      }
    }
  }

  // Indeterminate
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &:after {
        width: 10px;
        height: 10px;
        background-color: ${(props): string => props.theme.colors.black};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  // Indeterminate
  .ant-checkbox-indeterminate.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${(props): string => props.theme.colors.white};

      &:after {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }
  }
`;

import styled from 'styled-components';

export const StyledUpload = styled.div`
  .ant-upload-wrapper {
    min-height: 150px;

    .ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-action {
      height: 28px;
    }
  }

  .upload__description {
    margin-top: 8px;
  }

  .single-upload {
    .ant-upload.ant-upload-select {
      width: 100%;
    }

    .ant-upload-list.ant-upload-list-picture-card {
      .ant-upload-list-item-container {
        width: 100%;
      }
    }
  }
`;

import styled from 'styled-components';

export const StyledOdometer = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 16px;
  font-size: 16px;
  line-height: 16px;

  .odometer-digits {
    transition: 1s all;
    transition-timing-function: cubic-bezier(0, 0.99, 1, 1.01);
  }
`;

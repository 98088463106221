import styled from 'styled-components';
import { Select } from 'antd';

/* eslint-disable max-len */

export const StyledLegacySelect = styled(Select)`
  &.ant-select {
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
    .ant-select-selector {
      border-color: ${(props): string => props.theme.colors.semanticError};
    }
  }

  .ant-select-item,
  .ant-select-selection-search-input {
    color: ${(props): string => props.theme.colors.black};
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: 22px;
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  &.ant-select.ant-select-single,
  &.ant-select.ant-select-multiple {
    .ant-select-selector {
      height: 44px;
      padding: 0 ${(props): string => props.theme.spacing.base};
      padding-right: ${(props): string => props.theme.spacing.xl};
      border-radius: 0;
      border: 2px solid ${(props): string => props.theme.colors.black};

      :hover {
        background-color: ${(props): string => props.theme.colors.brightGrey};
      }

      :focus {
        border-color: ${(props): string => props.theme.colors.focusBorder};
      }

      :active {
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }

    &.ant-select.ant-select-single {
      .ant-select-selection-item {
        line-height: 44px;
        display: flex;
      }

      .ant-select-selector .ant-select-selection-search {
        inset-inline-start: 16px;
      }
    }

    &.ant-select.ant-select-multiple {
      .ant-select-selection-overflow {
        height: 100%;

        .ant-select-selection-overflow-item {
          line-height: 44px;
        }
      }

      .ant-select-selection-overflow {
        height: 100%;

        .ant-select-selection-overflow-item {
          line-height: 44px;
        }
      }
    }

    &.ant-select-open {
      .ant-select-selector {
        color: ${(props): string => props.theme.colors.darkGrey};
        background-color: ${(props): string => props.theme.colors.white};
      }
    }

    &.ant-select-disabled,
    &.ant-select-disabled:hover {
      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
        color: ${(props): string => props.theme.colors.darkGrey};
        border-color: ${(props): string => props.theme.colors.darkGrey};
      }

      .ant-select-arrow {
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${(props): string => props.theme.colors.focusBorder};
      }
    }

    .ant-select-arrow {
      color: ${(props): string => props.theme.colors.darkGrey};
      font-size: 1rem;
      width: fit-content;
      margin-top: -5px;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-right-width: 2px !important;
    box-shadow: none;
  }

  .ant-select-dropdown {
    border-radius: 0;
    padding: 0;

    .ant-select-item-option {
      height: 44px;
      display: flex;
      align-items: center;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: ${(props): string => props.theme.colors.darkGrey};
      padding: 14px 16px 13px;

      &:last-child {
        border-bottom: 2px solid ${(props): string => props.theme.colors.darkGrey};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: ${(props): string => props.theme.colors.white};
      background-color: ${(props): string => props.theme.colors.black};
      border-color: ${(props): string => props.theme.colors.black};

      .ant-select-item-option-state {
        color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }

  .ant-select-selection-placeholder {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: 44px;
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    top: 55%;
    left: 16px;
    color: ${(props): string => props.theme.colors.grey300};
  }

  &.ant-select-single .ant-select-selection-placeholder {
    padding: 6px 18px 6px 0px;
    color: ${(props): string => props.theme.colors.grey300};
  }

  &.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-overflow
    > .ant-select-selection-overflow-item-suffix:first-child
    .ant-select-selection-search-input {
    margin: 0 -7px;
    color: ${(props): string => props.theme.colors.black};
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }
`;

export const StyledOption = styled(Select.Option)`
  // TBD
`;

export const StyledOptGroup = styled(Select.OptGroup)`
  // TBD
`;

import { TextAreaProps as AntdTextAreaProps } from 'antd/es/input/TextArea';
import classnames from 'classnames';
import { StyledTextArea } from './TextArea.styles';

export type TextAreaProps = AntdTextAreaProps;

export const TextArea = (props: TextAreaProps): JSX.Element => {
  const { className, ...rest } = props;
  const textAreaClassName = classnames('text-area', className);

  return <StyledTextArea className={textAreaClassName} {...rest} />;
};

import { createGlobalStyle } from 'styled-components';
import { TIME_PICKER_WIDTH } from './TimePicker.styles';

export const TimePickerGlobalStyles = createGlobalStyle`
  .ant-picker-dropdown.time-picker__popup {
    .ant-picker-content {
      width: ${TIME_PICKER_WIDTH}px;
    }

    .ant-picker-panel-container {

      .ant-picker-time-panel-column {

        .ant-picker-time-panel-cell-inner {
          ${(props): string => props.theme.fontStyles.p1.medium}
          display: flex;
          align-items: center;
        }
      }
    }

    // add more specificity to override, TimePicker is missing in design token
    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: ${(props): string => props.theme.colors.primaryHover};
      }
    }

    .ant-picker-footer {
      .ant-picker-now-btn {
        ${(props): string => props.theme.fontStyles.p1.medium}
        color: ${(props): string => props.theme.colors.black};
      }

      .ant-picker-ok {
        .ant-btn {
          ${(props): string => props.theme.fontStyles.p1.medium}
          background-color: ${(props): string => props.theme.colors.primary};
          color: ${(props): string => props.theme.colors.black};
          border: 2px solid ${(props): string => props.theme.colors.primary};
        }
      }
    }
  }
`;

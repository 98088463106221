import classnames from 'classnames';
import { ButtonBaseProps } from '../ButtonBase';
import { StyledTextButton } from './TextButton.styles';

interface TextButtonProps extends ButtonBaseProps {
  size?: 's' | 'm' | '';
}

export const TextButton = (props: TextButtonProps): JSX.Element => (
  <StyledTextButton {...props} type="text" className={classnames('text-button', props.className)} />
);

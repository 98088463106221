import { Button, ButtonProps as AntdButtonProps } from 'antd';

interface ILegacyButtonBaseProps {
  size?: 's' | 'm';
}

export type LegacyButtonBaseProps = Partial<Omit<AntdButtonProps, 'size'> & ILegacyButtonBaseProps>;

export const LegacyButtonBase = (props: LegacyButtonBaseProps): JSX.Element => {
  const { size = 's', className = '', ...rest } = props;
  const buttonClassName = [className, 'legacy-button-base', `legacy-button__size-${size}`].join(' ');

  return <Button className={buttonClassName} {...rest} />;
};

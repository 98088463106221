import classnames from 'classnames';
import { FC, useMemo } from 'react';
import { StyledAvatarInitials } from './AvatarInitials.styles';
import { StringUtils } from 'lib/utils/string/StringUtils';

export interface AvatarInitialsProps {
  fullName: string;
  className?: string;
}

export const AvatarInitials: FC<AvatarInitialsProps> = (props): JSX.Element => {
  const { className, fullName } = props;
  const avatarInitialsClassName = classnames('avatar-initials', className);
  const userInitials = useMemo(() => StringUtils.generateUserInitials(fullName), [fullName]);

  return (
    <StyledAvatarInitials className={avatarInitialsClassName}>
      <div className="avatar-initials__letters">{userInitials}</div>
    </StyledAvatarInitials>
  );
};

import { isNil } from 'lodash-es';
import { Optional } from 'lib/types/Optional';

export class Numbers {
  public static FormattingPreset = {
    TWO_DECIMAL_PLACES: {
      maximumFractionDigits: 2,
    },
  };

  public static formatNumberByLocale = (locale: string, num: number, options?: Intl.NumberFormatOptions): string =>
    new Intl.NumberFormat(locale, options).format(num);

  public static formatNumberDecimalRounded = (data: Optional<number>, numDecimal: number): number => {
    if (!isNil(data) && !Number.isNaN(data)) {
      return parseFloat(data.toFixed(numDecimal));
    }

    return 0;
  };
}

import classnames from 'classnames';
import { LegacyButtonBaseProps } from '../LegacyButtonBase';
import { StyledLegacyPrimaryButton } from './LegacyPrimaryButton.styles';

type LegacyPrimaryButtonProps = LegacyButtonBaseProps;

export const LegacyPrimaryButton = ({ className, ...props }: LegacyPrimaryButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'legacy-button');

  return <StyledLegacyPrimaryButton className={buttonClassName} {...props} />;
};

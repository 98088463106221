/**
 * To understand how process.env.NODE_ENV is being set when using create-react-app
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
export enum Environment {
  IS_DEVELOPMENT_BUILD = 'development',
  IS_TEST_BUILD = 'test',
  IS_PRODUCTION_BUILD = 'production',
}

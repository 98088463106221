import styled from 'styled-components';

export const TABLE_FIXED_HEADER_DEFAULT_HEIGHT = 483;

interface StyledInfiniteScrollProps {
  height: number;
}

interface StyledTableProps {
  isFixedHeader?: boolean | undefined;
}

export const StyledInfiniteScrollWrapper = styled.div`
  &.table__infinite-scroll-wrapper {
    position: relative;
  }
`;

export const StyledInfiniteScroll = styled.div<StyledInfiniteScrollProps>`
  border: 1px solid ${(props): string => props.theme.colors.grey};
  overflow: auto;
  max-height: ${(props): number => props.height}px;

  .infinite-scroll-component {
    // override inline style
    overflow: unset !important;
  }

  .table {
    border: 0;
  }
`;

export const StyledTable = styled.div<StyledTableProps>`
  position: relative;
  border: 1px solid ${(props): string => props.theme.colors.grey};
  border-radius: 0;
  width: 100%;

  &.table {
    z-index: 1;
  }

  .ant-table-wrapper {
    .ant-table-column-title {
      width: 99%;
    }

    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
      &.active {
        color: ${(props): string => props.theme.colors.black};
      }
    }

    .ant-table-thead {
      th.ant-table-column-has-sorters:hover {
        background: ${(props): string => props.theme.colors.brightGrey};
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        min-height: 39px;
      }

      .ant-table-row > .ant-table-cell-row-hover {
        background-color: ${(props): string => props.theme.colors.brighterGrey};
      }

      .ant-table-row.ant-table-row-selected > .ant-table-cell-row-hover {
        background-color: ${(props): string => props.theme.colors.primary};
      }
    }
  }

  .ant-table-container {
    table {
      width: 100% !important;

      .ant-table-thead {
        > tr > th {
          // height for table cells acts like min-height
          height: 39px;
          padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.sm};
          background-color: ${(props): string => props.theme.colors.brightGrey};
          ${(props): string => props.theme.fontStyles.redesign.base.strong}
        }
      }

      .ant-table-tbody {
        tr > td {
          // height for table cells acts like min-height
          height: 39px;
          padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.sm};
          background-color: ${(props): string => props.theme.colors.white};
          ${(props): string => props.theme.fontStyles.redesign.base.normal}
        }
      }

      thead.ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 999;
      }
    }

    .ant-table-content {
      // override inline style
      overflow: unset !important;
    }
  }

  .table__title {
    width: 100%;
    ${(props): string => props.theme.mixins.truncateText}
  }

  .table__link--no-styling {
    color: ${(props): string => props.theme.colors.black};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .table__expansion-icon {
    cursor: pointer;
    user-select: none;
    ${(props): string => props.theme.mixins.flexCenter}

    .svg-icon {
      display: block;
      width: 20px;
      height: 20px;
      transition: transform 0.3s ease;
      will-change: transform;
    }

    &.table__expansion-icon--expanded {
      .svg-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .table__selected-row {
    td {
      background-color: ${(props): string => props.theme.colors.brighterGrey} !important;
    }
  }
`;

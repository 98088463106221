import classNames from 'classnames';
import React from 'react';
import { StyledCheckboxLayoutGroup } from './CheckboxLayoutGroup.styles';

export interface CheckboxLayoutGroupProps {
  className?: string;
  children: React.ReactNode;
}

/**
 * @name CheckboxLayoutGroup
 * @description This is a simple layout component to apply styles to a group of checkboxes.
 * This does not implement any of the functionality of the ant design <Checkbox.Group> component.
 * @example
 * <Checkbox.LayoutGroup>
 *   <Form.Item name="a" valuePropName="checked" noStyle>
 *      <Checkbox>Checkbox A</Checkbox>
 *   </Form.Item>
 *   <Form.Item name="b" valuePropName="checked" noStyle>
 *     <Checkbox>Checkbox B</Checkbox>
 *   </Form.Item>
 * </Checkbox.LayoutGroup>
 * @see https://ant.design/components/checkbox#checkbox-group
 */
export const CheckboxLayoutGroup = ({ className, children }: CheckboxLayoutGroupProps): JSX.Element => (
  <StyledCheckboxLayoutGroup className={classNames('checkbox-layout-group', className)}>
    {children}
  </StyledCheckboxLayoutGroup>
);

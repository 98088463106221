import { TimeRangePickerProps } from 'antd/lib/time-picker';
import classnames from 'classnames';
import { StyledTimeRangePicker } from './TimeRangePicker.styles';
import { ReactComponent as ClockIcon } from 'lib/assets/images/icons/Clock_icon.svg';

export const TimeRangePicker = (props: TimeRangePickerProps): JSX.Element => {
  const { className, format, use12Hours, allowClear = false, ...rest } = props;
  const rangePickerClassName = classnames('time-range-picker', className);
  const defaultFormat = 'HH:mmZ';

  const effectiveFormat = format ?? defaultFormat;
  const effectiveUse12Hours = use12Hours ?? false;

  return (
    <StyledTimeRangePicker
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      className={rangePickerClassName}
      format={effectiveFormat}
      use12Hours={effectiveUse12Hours}
      separator={'-'}
      suffixIcon={<ClockIcon />}
      allowClear={allowClear}
      {...rest}
    />
  );
};

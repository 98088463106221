import { ProgressProps as AntdProgressProps } from 'antd';
import classNames from 'classnames';
import { StyledBarProgress } from './BarProgress.styles';

type BarProgressProps = AntdProgressProps;

export const BarProgress = ({ className, ...props }: BarProgressProps): JSX.Element => {
  const barProgressClassName = classNames(className, 'bar-progress');

  return <StyledBarProgress className={barProgressClassName} {...props} type="line" />;
};

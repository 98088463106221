import styled from 'styled-components';

export const StyledIcon = styled.i<{ verticalAlign: number }>`
  vertical-align: ${(props): string => String(props.verticalAlign)}px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

import styled from 'styled-components';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';

export const StyledDatePicker = styled(DatePicker<Dayjs>)`
  height: 44px;
  padding: 4px 16px;
  border: 2px solid ${(props): string => props.theme.colors.darkGrey};
  text-transform: uppercase;

  &.ant-picker {
    width: 100%;

    &:hover,
    &:focus,
    &:active,
    &.ant-picker-focused {
      border-right-width: 2px !important;
    }

    &:hover {
      border-color: ${(props): string => props.theme.colors.darkGrey};
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }

    &:focus,
    &.ant-picker-focused {
      background-color: ${(props): string => props.theme.colors.white};
      border-color: ${(props): string => props.theme.colors.focusBorder};
      box-shadow: unset;
    }
  }

  .ant-picker-input {
    margin-bottom: -3px;

    > input {
      ${(props): string => props.theme.fontStyles.p1.medium}
      width: 82px;
    }
  }

  .ant-picker-suffix {
    color: ${(props): string => props.theme.colors.black};
    padding-left: 8px;
  }

  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    color: ${(props): string => props.theme.colors.black};
  }

  .ant-picker-date-panel {
    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn {
      color: ${(props): string => props.theme.colors.black};
    }

    & *:first-child button.ant-picker-header-next-btn {
      visibility: visible !important; /* override inline style */
    }

    & *:first-child button.ant-picker-header-super-next-btn {
      visibility: hidden !important; /* override inline style */
    }

    & *:first-child button.ant-picker-header-super-prev-btn {
      visibility: hidden !important; /* override inline style */
    }

    & .ant-picker-body {
      padding: 0 24px 24px 24px;
    }

    & table.ant-picker-content {
      width: 324px;

      & tr {
        height: 64px;
        padding: 10px 0;
      }

      // remove weekdays of upcoming month
      & tbody tr:last-child {
        display: none;
      }
    }

    // remove second picker
    & > .ant-picker-panel:nth-child(2) {
      display: none;
    }

    .ant-picker-header {
      border-bottom: none;
      height: 65px;
      display: flex;
      align-items: end;
      padding: 0 60px;
    }
  }

  .ant-picker-date-panel,
  .ant-picker-month-panel,
  .ant-picker-year-panel,
  .ant-picker-decade-panel {
    width: 376px;
    height: 463px;
    border: 2px solid ${(props): string => props.theme.colors.darkGrey};

    & .ant-picker-body {
      height: 100%;

      & .ant-picker-content {
        height: 100%;
        th {
          color: ${(props): string => props.theme.colors.black};
        }
      }
    }
  }

  .ant-picker-cell {
    height: 44px;
    padding: 0;

    &::before {
      height: 44px;
    }

    & .ant-picker-cell-inner {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;

      &:hover,
      :active,
      :focus {
        border-radius: 50%;
      }
    }
  }

  .ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
    width: 115px;
    border-radius: 0;
  }

  .ant-picker-year-panel .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 0;
    padding: 25px;
  }

  .ant-picker-decade-panel .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 0;
    padding: 30px;
  }

  .ant-picker-cell-in-view {
    &.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${(props): string => props.theme.colors.black};
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 2px solid ${(props): string => props.theme.colors.focusBorder};
  }

  .ant-picker-panel-container {
    border-radius: 0;

    .ant-picker-month-btn {
      text-transform: uppercase;
    }
  }

  .ant-picker-footer {
    border-top: 0;
    border-right: 2px solid ${(props): string => props.theme.colors.darkGrey};
    border-bottom: 2px solid ${(props): string => props.theme.colors.darkGrey};
    border-left: 2px solid ${(props): string => props.theme.colors.darkGrey};

    .ant-picker-today-btn {
      color: ${(props): string => props.theme.colors.black};
    }
  }
`;

import React from 'react';
import classnames from 'classnames';
import '../../assets/fonts/icons/css/kaercher_system_icons.css';
import '../../assets/fonts/icons/css/kaercher_system_icons-codes.css';
import { IIconName } from './interfaces/IconNames';
import { StyledIcon } from './Icon.styles';

export interface IIconProps {
  name: IIconName;
  className?: string;
  verticalAlign?: number;
}

/**
 * Icon component
 *
 * @param props - Icon props
 * @param props.name - Icon name
 * @param props.className - Additional class name to add to the icon
 * @param props.verticalAlign - Vertical align offset in px to apply to the icon
 * @constructor
 */
export const Icon: React.FC<IIconProps> = ({ name, className, verticalAlign = 0 }) => {
  const iconClassName = classnames('icon', className, name);

  return <StyledIcon className={iconClassName} verticalAlign={verticalAlign} />;
};

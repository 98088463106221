import { ProgressProps as AntdProgressProps } from 'antd';
import classNames from 'classnames';
import { StyledCircleProgress } from './CircleProgress.styles';
import { theme } from 'config/theme';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';

type CircleProgressProps = AntdProgressProps & {
  circleProgressColor?: string;
  onClickCircleProgress?: () => void;
};

export const CircleProgress = ({
  className,
  circleProgressColor,
  onClickCircleProgress,
  ...props
}: CircleProgressProps): JSX.Element => {
  const circleProgressClassName = classNames(className, 'circle-progress');

  const getCircleProgressFormat = (percentData: number | undefined): React.ReactNode => {
    if (onClickCircleProgress) {
      return (
        <TextButton className="circle-progress__percentage-circle" onClick={onClickCircleProgress}>
          <span className="circle-progress__percentage-data">{percentData || 0}&#37;</span>
        </TextButton>
      );
    }

    return <>{percentData || 0}&#37;</>;
  };

  return (
    <StyledCircleProgress
      dynamicColor={circleProgressColor || theme.colors.gray85}
      className={circleProgressClassName}
      {...props}
      type="circle"
      format={(percentData): React.ReactNode => getCircleProgressFormat(percentData)}
    />
  );
};

import { DefaultOptionType, BaseOptionType, SelectProps as AntSelectProps } from 'antd/lib/select';
import classnames from 'classnames';
import { StyledOptGroup, StyledOption, StyledLegacySelect } from './LegacySelect.styles';

type LegacySelectProps = AntSelectProps & {
  className?: string;
  selectedOptionsOnTop?: boolean;
};

const selectedOptionsOnTopCompareFn =
  (selectedOptions: string[]) =>
  <OptionType extends BaseOptionType | DefaultOptionType>(optionA: OptionType, optionB: OptionType): number => {
    if (!selectedOptions) return 0;
    const aIncluded = selectedOptions?.includes(optionA.value);
    const bIncluded = selectedOptions?.includes(optionB.value);

    if (aIncluded && bIncluded) {
      return 0;
    }
    if (!aIncluded && !bIncluded) {
      return 0;
    }
    if (aIncluded) {
      return -1;
    }
    return 1;
  };

export const LegacySelect = (props: LegacySelectProps): JSX.Element => {
  const { selectedOptionsOnTop, ...rest } = props;
  // make effectiveSelectedOptionsOnTop true as default
  const effectiveSelectedOptionsOnTop = props.selectedOptionsOnTop ?? true;
  const effectiveShowSearch = props.showSearch ?? true;
  const effectiveOptionFilterProp = props.optionFilterProp ?? 'label';

  return (
    // @ts-ignore
    <StyledLegacySelect
      {...rest}
      className={classnames('legacy-select', props.className)}
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      dropdownAlign={{ offset: [0, -2] }}
      showSearch={effectiveShowSearch}
      optionFilterProp={effectiveOptionFilterProp}
      filterSort={effectiveSelectedOptionsOnTop ? selectedOptionsOnTopCompareFn(props.value as string[]) : undefined}
    />
  );
};

LegacySelect.Option = StyledOption;
LegacySelect.OptGroup = StyledOptGroup;

import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)`
  font-family: ${(props): string => props.theme.fonts.captionMedium};
  font-size: ${(props): string => props.theme.fontSizes.captionSmall};
  font-weight: ${(props): number => props.theme.fontWeights.p0};
  line-height: ${(props): string => props.theme.lineHeights.captionSmall};
  height: 125px;

  &.ant-input,
  .ant-input {
    ${(props): string => props.theme.fontStyles.p1.medium};
    min-height: 60px;
    border: 2px solid ${(props): string => props.theme.colors.darkGrey};
    resize: vertical;
    padding: 12px 16px;
    margin-bottom: 4px;

    :hover {
      background-color: ${(props): string => props.theme.colors.brightGrey};
      border: 2px solid ${(props): string => props.theme.colors.darkGrey};
      box-shadow: none;
    }

    :focus {
      border: 2px solid ${(props): string => props.theme.colors.focusBorder};
      box-shadow: none;
    }
  }
`;

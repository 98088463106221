import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  &.modal--rounded .ant-modal-content {
    border-radius: ${(props): string => props.theme.borderRadiuses.lg};
  }

  .ant-select-dropdown {
    .machine-image {
      margin-right: 12px;
    }
  }

  .ant-select-selection-item-content {
    .machine-image {
      display: none;
    }
  }

  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-title {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
    text-transform: uppercase;
  }

  .modal__content {
    display: flex;
    flex-direction: row;
    gap: ${(props): string => props.theme.spacing.sm};
  }

  .modal__icon {
    width: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .modal__icon--error {
    color: ${(props): string => props.theme.colors.semanticError};

    svg {
      color: ${(props): string => props.theme.colors.semanticError};
      fill: ${(props): string => props.theme.colors.semanticError};
    }
  }

  .modal__message {
    display: flex;
    flex-direction: column;
    gap: ${(props): string => props.theme.spacing.xs};
  }

  .modal__title--lg-strong {
    ${(props): string => props.theme.fontStyles.redesign.lg.strong};
  }

  .modal__description {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }

  .modal__body {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
  }

  .modal__sub-title {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
  }

  .ant-modal-footer {
    text-align: unset;
    border-top: unset;
    display: flex;
    justify-content: flex-end;
  }

  .input-label {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
  }

  .modal-select-space {
    width: 100%;
  }

  .ant-form-item-label {
    padding-bottom: 0;

    > label {
      text-transform: uppercase;
      font-family: ${(props): string => props.theme.fonts.captionBold};
      font-size: ${(props): string => props.theme.fontSizes.caption};
      line-height: ${(props): string => props.theme.lineHeights.caption};
      font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    }
  }
`;

import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
  padding: 0 8px;
  margin: 0 2px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: ${(props): string => props.theme.borderRadiuses.sm};
  display: flex;
  align-items: center;

  .ant-space-item {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .tag__content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 17px;
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }
`;

import styled from 'styled-components';
import { ThemeConstants } from '../../../config/theme';

export interface StyledStatusPillProps {
  color: 'green' | 'yellow' | 'red' | 'blue' | 'gray' | string;
}

export const StyledStatusPill = styled.div<StyledStatusPillProps>`
  display: inline-block;
  width: max-content;
  padding: 3px ${ThemeConstants.SPACING.SM};
  border-radius: ${(props): string => props.theme.borderRadiuses.pill};
  background-color: ${(props): string => props.color};

  &.status-pill--size-small {
    height: 20px;
    width: 48px;
    padding: 2px ${ThemeConstants.SPACING.SM} 4px;
    border-radius: ${(props): string => props.theme.borderRadiuses.pillSmall};
  }
`;

import classnames from 'classnames';
import { LegacyButtonBaseProps } from '../LegacyButtonBase';
import { StyledLegacyTextButton } from './LegacyTextButton.styles';

interface LegacyTextButtonProps extends LegacyButtonBaseProps {
  size?: 's';
}

export const LegacyTextButton = (props: LegacyTextButtonProps): JSX.Element => (
  <StyledLegacyTextButton {...props} type="text" className={classnames('legacy-text-button', props.className)} />
);

import { SelectProps as AntdSelectProps } from 'antd';
import { SelectValue as AntdSelectValue } from 'antd/lib/select';
import { StyledOptGroup, StyledOption, StyledPaginationSelect } from './PaginationSelect.styles';
import { ReactComponent as RightArrow } from 'lib/assets/images/rightArrow.svg';

type PaginationSelectProps = AntdSelectProps<AntdSelectValue>;

export const PaginationSelect = (props: PaginationSelectProps): JSX.Element => (
  // @ts-ignore
  <StyledPaginationSelect
    getPopupContainer={(triggerNode): HTMLElement => triggerNode}
    {...props}
    suffixIcon={<RightArrow />}
  />
);

PaginationSelect.Option = StyledOption;
PaginationSelect.OptGroup = StyledOptGroup;

// eslint-disable-next-line import/no-unresolved
import * as GeoJSON from 'geojson';

export class GeoJson {
  public static getLongitude = (position: GeoJSON.Position): number => position[0];
  public static getLatitude = (position: GeoJSON.Position): number => position[1];

  public static createPosition({ longitude, latitude }: { longitude: number; latitude: number }): number[] {
    return [longitude, latitude];
  }
}

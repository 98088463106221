import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const getMonthsByPeriod = (startDate: string | null, endDate: string | null): string[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const months: string[] = [];
  if (!start || !end) {
    return months;
  }
  let currentDate = start.startOf('month');

  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    months.push(currentDate.format('MMMM YYYY'));
    currentDate = currentDate.add(1, 'month').startOf('month');
  }

  return months;
};

export const getDatesByPeriod = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null): dayjs.Dayjs[] => {
  const daysArr: dayjs.Dayjs[] = [];
  let currentDate = startDate;
  if (!currentDate || !endDate) {
    return daysArr;
  }

  while (!currentDate.isAfter(endDate)) {
    daysArr.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  return daysArr;
};

import { Popconfirm, PopconfirmProps } from 'antd';
import React from 'react';
import { PopConfirmGlobalStyles } from './PopConfirm.global.styles';
import { theme } from 'config/theme';

export const PopConfirm = ({ ...props }: PopconfirmProps): React.JSX.Element => (
  <>
    <Popconfirm
      placement="top"
      icon={null}
      okButtonProps={{
        style: {
          backgroundColor: theme.colors.primary,
          color: theme.colors.black,
        },
      }}
      cancelButtonProps={{
        style: {
          borderColor: 'transparent',
        },
      }}
      {...props}
    />
    <PopConfirmGlobalStyles />
  </>
);

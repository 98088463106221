import styled from 'styled-components';
import { TimePicker } from 'antd';

export const TIME_PICKER_WIDTH = 256;
export const StyledTimePicker = styled(TimePicker)`
  width: ${TIME_PICKER_WIDTH}px;
  height: 44px;
  padding: 4px 16px;
  border: 2px solid ${(props): string => props.theme.colors.darkGrey};
  text-transform: uppercase;

  .ant-picker-input {
    margin-bottom: -3px;

    > input {
      ${(props): string => props.theme.fontStyles.p1.medium}
    }
  }

  .ant-picker-suffix {
    color: ${(props): string => props.theme.colors.black};
    padding-left: 8px;
  }

  .time-picker__icon {
    width: 15.8px;
  }

  &.ant-picker {
    &:hover,
    &:focus,
    &:active,
    &.ant-picker-focused {
      border-right-width: 2px !important;
    }

    &:hover {
      border-color: ${(props): string => props.theme.colors.darkGrey};
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }

    &:focus,
    &.ant-picker-focused {
      background-color: ${(props): string => props.theme.colors.white};
      border-color: ${(props): string => props.theme.colors.focusBorder};
      box-shadow: unset;
    }
  }
`;

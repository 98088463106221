import styled from 'styled-components';

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(props): string => props.theme.fonts.p1News};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1News};

  .ant-row {
    width: 100%;
    align-items: center;

    .pagination__body-col {
      .pagination__body {
        justify-content: center;
      }
    }
  }

  .pagination__body {
    display: flex;
    align-items: center;

    .pagination__items {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: -4px;
      text-align: center;
    }
  }

  .pagination__page-size-data {
    margin-left: 18px;
  }

  .pagination__prev-button,
  .pagination__next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      stroke: ${(props): string => props.theme.colors.black};
    }

    :hover {
      path {
        stroke: ${(props): string => props.theme.colors.black};
      }
    }

    &.button-base.button__size-s {
      padding: 0;
      height: 18px;
      width: 18px;
    }

    &[disabled],
    &[disabled]:hover {
      path {
        stroke: ${(props): string => props.theme.colors.grey};
      }
    }

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pagination__prev-button svg {
    transform: rotate(180deg);
  }

  .ant-select.ant-select-single {
    height: auto;
  }
`;

import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  height: 32px;
  background-color: ${(props): string => props.theme.colors.white};
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props): string => props.theme.colors.darkGrey};

  & .ant-input::placeholder {
    position: absolute;
    top: 9px;
    color: ${(props): string => props.theme.colors.grey300};
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }

  &.ant-input,
  &.ant-input-affix-wrapper > input.ant-input {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }

  :hover {
    background-color: ${(props): string => props.theme.colors.brightGrey};
    border-color: ${(props): string => props.theme.colors.darkGrey};
    border-right-width: 1px !important;

    input {
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }
  }

  :active {
    background-color: ${(props): string => props.theme.colors.white};

    input {
      background-color: ${(props): string => props.theme.colors.white};
    }
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.semanticError};
  }

  &.ant-input-affix-wrapper-disabled,
  &.ant-input-affix-wrapper-disabled:hover {
    border-color: ${(props): string => props.theme.colors.brightGrey};
    background-color: ${(props): string => props.theme.colors.white};
    color: ${(props): string => props.theme.colors.brightGrey};
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }

  :focus-within,
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within {
    border-color: ${(props): string => props.theme.colors.focusBorder};
    box-shadow: none;
    border-right-width: 1px !important;
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;

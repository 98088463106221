import styled from 'styled-components';
import { ProgressBase } from '../ProgressBase';

export const StyledCircleProgress = styled(ProgressBase)<{ dynamicColor: string }>`
  &.ant-progress-circle,
  &.ant-progress-status-success {
    .ant-progress-inner {
      svg {
        .ant-progress-circle-trail {
          stroke: ${(props): string => props.theme.colors.gray85} !important;
          stroke-width: 8px;
        }

        .ant-progress-circle-path {
          stroke: ${(props): string => props.dynamicColor} !important;
          stroke-width: 8px;
          transform: rotate(0deg) !important;
        }
      }
    }

    .circle-progress__percentage-circle {
      width: 86px;
      height: 86px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props): string => props.dynamicColor} !important;

      .circle-progress__percentage-data {
        color: ${(props): string => props.theme.colors.white};
        ${(props): string => props.theme.fontStyles.p1.news};
        font-size: 22px;
        margin-top: 4px;
      }
    }
  }

  .ant-progress-text {
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      button {
        background-color: ${(props): string => props.dynamicColor};
      }
    }
  }
`;

import { CheckboxProps as AntdCheckboxProps } from 'antd';
import classNames from 'classnames';
import { StyledLegacyCheckbox } from './LegacyCheckbox.styles';
import { LegacyCheckboxLayoutGroup } from './LegacyCheckboxLayoutGroup/LegacyCheckboxLayoutGroup';

interface LegacyCheckboxProps extends AntdCheckboxProps {
  whiteMode?: boolean;
}
export const LegacyCheckbox = ({ whiteMode, className, ...props }: LegacyCheckboxProps): JSX.Element => {
  const effectiveClassName = classNames('legacy-checkbox', className, {
    'white-mode': whiteMode,
  });

  return <StyledLegacyCheckbox className={effectiveClassName} {...props} />;
};

LegacyCheckbox.LayoutGroup = LegacyCheckboxLayoutGroup;

import styled from 'styled-components';
import { LegacyButtonBase } from '../LegacyButtonBase';

export const StyledLegacyTextButton = styled(LegacyButtonBase)`
  font-family: ${(props): string => props.theme.fonts.p1Medium};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  border: none;
  background-color: transparent;
  color: ${(props): string => props.theme.colors.black};
  box-shadow: none;

  :hover,
  :focus,
  :active {
    background-color: transparent;
    color: ${(props): string => props.theme.colors.black};
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    background-color: transparent;
  }

  &.legacy-button__size-s {
    height: auto;
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    padding: 8px 14px 7px;
  }
`;

import { CardProps as AntdCardProps } from 'antd';
import { StyledCard } from './Card.styles';

type CardProps = AntdCardProps;

export const Card = (props: CardProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const cardClassName = [className, 'card'].join(' ');

  return (
    <StyledCard className={cardClassName} {...rest}>
      {children}
    </StyledCard>
  );
};

import classnames from 'classnames';
import { ButtonBaseProps } from '../ButtonBase';
import { StyledPrimaryButton } from './PrimaryButton.styles';

type PrimaryButtonProps = ButtonBaseProps;

export const PrimaryButton = ({ className, ...props }: PrimaryButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'button', 'primary-button');

  return <StyledPrimaryButton className={buttonClassName} {...props} />;
};

import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import { Optional } from 'lib/types/Optional';

interface GetSingleSelectionPropsOptions {
  dropdownVisibleState: boolean;
  onDropdownVisibleChange: (isOpen: boolean) => void;
  value: any;
  dropdownLabel: string;
  showSearch: boolean;
}

interface GetSingleSelectionPropsReturnValue {
  value: any;
  onDropdownVisibleChange: (isOpen: boolean) => void;
  dropdownVisibleState: boolean;
  labelRender: () => React.ReactElement;
  showSearch: boolean;
}

interface GetMultiSelectionPropsOptions {
  options: DefaultOptionType[] | undefined;
  onDropdownVisibleChange: (isOpen: boolean) => void;
  dropdownVisibleState: boolean;
  valueArray: Optional<(string | number | null)[]>;
  dropdownLabel: string;
  mode: SelectProps['mode'];
}

interface GetMultiSelectionPropsReturnValue {
  value: Optional<(string | number | null)[]>;
  mode: SelectProps['mode'];
  options: DefaultOptionType[] | undefined;
  tagRender?: (props: {
    label: React.ReactNode;
    value: any;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
  }) => React.ReactElement;
  onDropdownVisibleChange: (isOpen: boolean) => void;
  dropdownVisibleState: boolean;
  placeholder: string;
}

export class SelectUtils {
  public static readonly SINGLE_SELECT_ALL_OPTION = '';

  public static getSingleSelectionProps = ({
    dropdownVisibleState,
    onDropdownVisibleChange,
    value,
    dropdownLabel,
    showSearch,
  }: GetSingleSelectionPropsOptions): GetSingleSelectionPropsReturnValue => ({
    value,
    onDropdownVisibleChange,
    dropdownVisibleState,
    labelRender: (): React.ReactElement => {
      if (showSearch && dropdownVisibleState) return <></>;

      if (value !== SelectUtils.SINGLE_SELECT_ALL_OPTION) {
        return (
          <div className="select__dropdown-label">
            <div className="select__dropdown-label-text">{dropdownLabel}</div>
            <div className="select__counter">1</div>
          </div>
        );
      }

      return <>{dropdownLabel}</>;
    },
    showSearch,
  });

  public static getMultiSelectionProps = ({
    options,
    onDropdownVisibleChange,
    dropdownVisibleState,
    valueArray,
    dropdownLabel,
    mode,
  }: GetMultiSelectionPropsOptions): GetMultiSelectionPropsReturnValue => ({
    value: valueArray,
    mode,
    options,
    tagRender:
      mode === 'tags'
        ? undefined
        : ({ value }): React.ReactElement => {
            const effectiveValueArray = valueArray ?? [];
            const hideCounter = effectiveValueArray?.length < 1;

            if (dropdownVisibleState) return <></>;

            // Only render one label, not one per item
            if (effectiveValueArray?.[0] === value) {
              return (
                <div className="select__dropdown-label">
                  <div className="select__dropdown-label-text">{dropdownLabel}</div>
                  {!hideCounter && <div className="select__counter">{effectiveValueArray?.length}</div>}
                </div>
              );
            }

            return <></>;
          },
    onDropdownVisibleChange,
    dropdownVisibleState,
    placeholder: dropdownLabel,
  });
}

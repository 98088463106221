/**
 *
 * According to ISO week date numbering is from Monday (1) to Sunday (7)
 *
 * @see https://en.wikipedia.org/wiki/ISO_week_date
 * @see https://momentjs.com/docs/#/get-set/iso-weekday/
 */
export enum Weekday {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export enum DATE_FORMAT_PATTERN {
  DATE_TIME,
  DATE,
  DATE_DAY_AND_MONTH,
  DATE_WITH_WEEKDAY_NAME,
  TIME,
  TIME_12_HOUR,
  TIME_24_HOUR,
  TIME_24_HOUR_WITH_SECOND,
  TIME_WITHOUT_SECOND,
  WEEKDAY,
  YEAR,
}

export const MAPPING_INTL_DATE_TIME_FORMAT_OPTIONS: Record<DATE_FORMAT_PATTERN, Intl.DateTimeFormatOptions> = {
  [DATE_FORMAT_PATTERN.DATE_TIME]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [DATE_FORMAT_PATTERN.DATE]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  [DATE_FORMAT_PATTERN.DATE_WITH_WEEKDAY_NAME]: {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  [DATE_FORMAT_PATTERN.DATE_DAY_AND_MONTH]: {
    month: 'numeric',
    day: 'numeric',
  },
  [DATE_FORMAT_PATTERN.TIME]: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [DATE_FORMAT_PATTERN.TIME_12_HOUR]: {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
  [DATE_FORMAT_PATTERN.TIME_24_HOUR]: {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
  },
  [DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND]: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hourCycle: 'h24',
  },
  [DATE_FORMAT_PATTERN.TIME_WITHOUT_SECOND]: {
    hour: 'numeric',
    minute: 'numeric',
  },
  [DATE_FORMAT_PATTERN.WEEKDAY]: {
    weekday: 'long',
  },
  [DATE_FORMAT_PATTERN.YEAR]: {
    year: 'numeric',
  },
};

export enum TimezoneType {
  UTC = 'utc',
  LOCAL = 'local',
}

export const AwsDateFormatPattern = {
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm:ss.SSS',
  DATE_TIME: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
};

import GoogleMapReact, { Coords, fitBounds } from 'google-map-react';
import { isNil, isEqual } from 'lodash-es';

const SINGLE_POINT_BOUNDING_BOX_PADDING = 0.5;
export const ZOOM_FALLBACK = 0;
export const CENTER_FALLBACK: Coords = {
  lat: 48.43919727139413,
  lng: 9.743455501696545,
};

export interface CalculateBoundingBoxOptions {
  googleApisInitialized: boolean;
  mapApisRef: React.MutableRefObject<any>;
  points: GoogleMapReact.Coords[];
}

export interface CalculateCenterOptions {
  points: GoogleMapReact.Coords[];
  boundingBox: GoogleMapReact.NESWBounds | undefined;
  size: { width: number; height: number };
}

export interface CalculateZoomOptions {
  points: GoogleMapReact.Coords[];
  boundingBox: GoogleMapReact.NESWBounds | undefined;
  size: { width: number; height: number };
  enableZoomPadding: number | boolean;
}

export class MapUtils {
  public static calculateBoundingBox = ({
    googleApisInitialized,
    mapApisRef,
    points,
  }: CalculateBoundingBoxOptions): GoogleMapReact.NESWBounds | undefined => {
    if (!googleApisInitialized || isNil(mapApisRef) || isNil(mapApisRef.current) || points.length < 1) {
      return undefined;
    }

    const bounds: google.maps.LatLngBounds = new mapApisRef.current.LatLngBounds();
    points.forEach((point: Coords) => bounds.extend(point));

    if (points.length === 1) {
      return {
        ne: {
          lat: bounds.getNorthEast().lat() + SINGLE_POINT_BOUNDING_BOX_PADDING,
          lng: bounds.getNorthEast().lng() + SINGLE_POINT_BOUNDING_BOX_PADDING,
        },
        sw: {
          lat: bounds.getSouthWest().lat() - SINGLE_POINT_BOUNDING_BOX_PADDING,
          lng: bounds.getSouthWest().lng() - SINGLE_POINT_BOUNDING_BOX_PADDING,
        },
      };
    }

    return {
      ne: {
        lat: bounds.getNorthEast().lat(),
        lng: bounds.getNorthEast().lng(),
      },
      sw: {
        lat: bounds.getSouthWest().lat(),
        lng: bounds.getSouthWest().lng(),
      },
    };
  };

  public static calculateCenter = ({
    points,
    boundingBox,
    size,
  }: CalculateCenterOptions): GoogleMapReact.Coords | undefined => {
    if (points.length <= 0 || boundingBox === undefined) {
      return undefined;
    }
    if (isEqual(boundingBox.ne, boundingBox.sw)) return boundingBox.ne;
    // Calculate center from bounding box
    const { center: centerValue } = fitBounds(boundingBox, size);
    return centerValue;
  };

  public static calculateZoom = ({
    points,
    boundingBox,
    size,
    enableZoomPadding,
  }: CalculateZoomOptions): number | undefined => {
    // Calculate zoom from bounding box
    if (points.length > 0 && boundingBox !== undefined) {
      const { zoom: zoomValue } = fitBounds(boundingBox, size);

      // If zoom padding is enabled return a zoom level one step zoomed out. But never less than 0.
      if (enableZoomPadding) {
        return Math.max(0, zoomValue - 1);
      }

      return zoomValue;
    }

    return undefined;
  };
}

import { AutoCompleteProps as AntdAutoCompleteProps } from 'antd';
import classnames from 'classnames';
import { StyledAutoComplete } from './AutoComplete.styles';

export type AutoCompleteProps = AntdAutoCompleteProps;

export const AutoComplete = (props: AutoCompleteProps): JSX.Element => {
  const { className, children, ...rest } = props;
  const autoCompleteClassName = classnames('auto-complete', className);

  return (
    // @ts-ignore
    <StyledAutoComplete className={autoCompleteClassName} {...rest}>
      {children}
    </StyledAutoComplete>
  );
};

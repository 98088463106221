import { createGlobalStyle } from 'styled-components';

export const InfoTooltipGlobalStyles = createGlobalStyle`
  .tooltip-overlay {
    padding-top: 0px;

    .ant-tooltip-inner {
      float: right;
      width: fit-content;
      padding: 16px;
    }
  }
`;

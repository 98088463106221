import styled from 'styled-components';

export const LEGACY_TABLE_FIXED_HEADER_DEFAULT_HEIGHT = 480;

interface StyledLegacyTableProps {
  isFixedHeader?: boolean | undefined;
}

export const StyledLegacyTable = styled.div<StyledLegacyTableProps>`
  border: 1px solid ${(props): string => props.theme.colors.grey};
  border-radius: 0;

  .ant-table-container {
    border-radius: 0;
    border-color: ${(props): string => props.theme.colors.brightGrey};

    table {
      border-collapse: collapse;

      .ant-table-thead {
        > tr:first-child th {
          border-radius: 0;
        }

        > tr > th {
          background-color: ${(props): string => props.theme.colors.grey};
          font-family: ${(props): string => props.theme.fonts.captionBold};
          font-size: ${(props): string => props.theme.fontSizes.caption};
          line-height: ${(props): string => props.theme.lineHeights.caption};
          font-weight: ${(props): number => props.theme.fontWeights.captionBold};
          color: ${(props): string => props.theme.colors.black};
          text-transform: uppercase;
          padding: 17px 24px;
          border: none;
          height: 48px;

          &:before {
            display: none;
          }

          .table-head--with-icon {
            display: flex;
            align-items: center;

            .table-head__icon {
              margin-left: 15px;
            }
          }
        }

        tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
          &::before {
            width: 0px;
          }
        }
      }


      .ant-table-tbody {
        tr:nth-child(even) {
          background-color: ${(props): string =>
            props.isFixedHeader ? props.theme.colors.white : props.theme.colors.brightGrey};
        }

        tr:nth-child(odd) {
          background-color: ${(props): string =>
            props.isFixedHeader ? props.theme.colors.brightGrey : props.theme.colors.white};
        }
        
        tr > td {
          font-family: ${(props): string => props.theme.fonts.p1News};
          font-size: ${(props): string => props.theme.fontSizes.p1};
          line-height: ${(props): string => props.theme.lineHeights.p1};
          font-weight: ${(props): number => props.theme.fontWeights.p1News};
          color: ${(props): string => props.theme.colors.black};
          border-bottom: none;
          border-top: 1px solid ${(props): string => props.theme.colors.brightGrey};
          padding: 24px 24px 23px;
          overflow-wrap: anywhere;
        }

        tr {
          &:hover {
            background-color: ${(props): string => props.theme.colors.brighterGrey};
          }
        }
      }

      .ant-table-expanded-row {
        .ant-table-cell {
          background-color: white;
        }
      }

      .ant-table-expanded-row-level-1 {
        .ant-table-cell {
          border-top: none;
        }
      }
    }

    .ant-table-footer {
      background-color: ${(props): string => props.theme.colors.white};
      padding: 20px 24px;
    }

    .legacy-table__link--no-styling {
      color: ${(props): string => props.theme.colors.black};
    }

    td.ant-table-column-sort {
      background: inherit;
    }

    .ant-table-column-sorters {
      display: block;
    }

    .ant-table-column-sorter {
      display: none;
    }

    .legacy-table__expansion-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .legacy-table__expansion-icon {
      cursor: pointer;
      user-select: none;

      .svg-icon {
        display: block;
        width: 24px;
        height: 24px;
        transition: transform 0.3s ease;
        will-change: transform;
      }

      &.legacy-table__expansion-icon--expanded {
        .svg-icon {
          transform: rotate(-180deg);
        }
      }
    }
`;

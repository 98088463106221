import styled from 'styled-components';

export const StyledAvatarInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: ${(props): string => props.theme.colors.darkGrey};
  border-radius: 50%;
  font-family: ${(props): string => props.theme.fonts.heading3};
  font-size: ${(props): string => props.theme.fontSizes.heading3};
  line-height: ${(props): string => props.theme.lineHeights.heading3};
  font-weight: ${(props): number => props.theme.fontWeights.heading3};
  color: ${(props): string => props.theme.colors.white};

  .avatar-initials__letters {
    margin-bottom: -4px;
  }
`;

import { t } from 'i18next';
import { Dayjs } from 'dayjs';
import { has, isArray } from 'lodash-es';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidatorRule } from 'rc-field-form/lib/interface';

export type FormItemValidator = ValidatorRule['validator'];

export class TimeRangeValidator {
  public static validateStartTimeEndTimeNotIdentical: FormItemValidator = async (
    _ruleObject,
    value: [Dayjs, Dayjs]
  ) => {
    if (!isArray(value)) {
      return Promise.reject();
    }

    if (!has(value, '0')) {
      return Promise.reject();
    }

    if (!has(value, '1')) {
      return Promise.reject();
    }

    const startDateHours = value[0].hour();
    const startDateMinutes = value[0].minute();
    const endDateHours = value[1].hour();
    const endDateMinutes = value[1].minute();
    const isSameTime = startDateHours === endDateHours && startDateMinutes === endDateMinutes;

    if (isSameTime) {
      return Promise.reject(t('timeRangeValidator.errors.startTimeEndTimeIdentical'));
    }

    return Promise.resolve(true);
  };
}

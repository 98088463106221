/* eslint-disable max-len */
import {
  addDays,
  addMonths,
  compareAsc,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  getISOWeek,
  parseISO,
  startOfWeek,
  sub,
} from 'date-fns/esm';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { DATE_FORMAT_PATTERN, MAPPING_INTL_DATE_TIME_FORMAT_OPTIONS, TimezoneType } from './DateTime.types';
import { Optional } from 'lib/types/Optional';

export interface RoundDurationByToMinutesOptions {
  ms: number;
}

export interface FormatDurationByMillisecondsOptions {
  ms: number;
  showMinutes?: boolean;
  showSeconds?: boolean;
  padWithZeros?: boolean;
}

export interface FormatDurationByStartAndEndDateOptions {
  startDate: string;
  endDate: string;
  showMinutes?: boolean;
  showSeconds?: boolean;
  padWithZeros?: boolean;
}

export interface GetDayOfWeekOptions {
  date: string;
  weekStartsOnMonday?: boolean;
}

export interface GetDateByDayOfWeek {
  weekday: number;
  hours: number;
  minutes: number;
  timezoneType: TimezoneType;
}

interface RoundTime {
  hours: number;
  minutes: number;
  seconds: number;
  isRoundSeconds?: boolean;
  isRoundMinutes?: boolean;
}

export class DateTime {
  public static TIME_ZONE_FALLBACK = 'Europe/Berlin';
  public static initialize(): void {
    dayjs.extend(updateLocale);
    dayjs.extend(duration);
    dayjs.extend(utc);
    dayjs.extend(isoWeek);
    dayjs.extend(timezone);
  }

  public static today = (): Date => new Date();
  public static oneWeekAgo = (): Date => addDays(DateTime.today(), -7);
  public static someDaysAgo = (days: number): Date => addDays(DateTime.today(), -days);
  public static oneDayAgo = (): Date => addDays(DateTime.today(), -1);
  public static someMonthsAgo = (months: number): Date => addMonths(DateTime.today(), -months);
  public static threeDaysAgo = (): Date => addDays(DateTime.today(), -3);
  public static beginningOfEpochTime = (): Date => new Date(0);

  public static convertMsToHours = (ms: number): number => ms / 1000 / 3600;
  public static convertSecondsToMs = (s: number): number => s * 1000;

  /**
   * @param locale - It must be passed as value of `i18n.language`
   * @param timestamp - It should be defined as ISO 8601 timestamp string
   * E.g. `2021-08-24T14:00:00.000Z` or `2021-08-24T16:00:00.000+0200`
   * Otherwise the parsing with `new Date()` is unreliable.
   * @param dateFormatPattern - Use enum DATE_FORMAT_PATTERN
   * @returns {string} - String of date formatted according to the i18n's language as locale
   *
   * References:
   * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#timestamp_string
   * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
   */
  public static formatDateByLocale = (
    locale: string,
    timestamp: Optional<string>,
    dateFormatPattern: DATE_FORMAT_PATTERN = DATE_FORMAT_PATTERN.DATE_TIME
  ): string => {
    if (!timestamp) return '';

    return new Intl.DateTimeFormat(locale, MAPPING_INTL_DATE_TIME_FORMAT_OPTIONS[dateFormatPattern]).format(
      new Date(timestamp)
    );
  };

  public static getBrowserTimeZone = (): string => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const effectiveTimeZone = browserTimeZone ?? DateTime.TIME_ZONE_FALLBACK;

    return effectiveTimeZone;
  };

  public static roundMsDurationToMinutes = ({ ms }: RoundDurationByToMinutesOptions): number => {
    if (!Number.isInteger(ms)) {
      throw Error('Argument "ms" needs to be a integer.');
    }

    const durationInMsRoundedDownToMinutes = Math.floor(ms / 60000) * 60000;

    return durationInMsRoundedDownToMinutes;
  };

  public static formatDurationByMilliseconds = ({
    ms,
    showMinutes = true,
    showSeconds = true,
    padWithZeros = true,
  }: FormatDurationByMillisecondsOptions): string => {
    if (!Number.isInteger(ms)) {
      throw Error('Argument "ms" needs to be a integer.');
    }

    const startDate = new Date(0).toISOString();
    const endDate = new Date(ms).toISOString();

    return DateTime.formatDurationByStartAndEndDate({
      startDate,
      endDate,
      showMinutes,
      showSeconds,
      padWithZeros,
    });
  };

  public static formatDurationByStartAndEndDate = ({
    startDate,
    endDate,
    showMinutes = true,
    showSeconds = true,
    padWithZeros = true,
  }: FormatDurationByStartAndEndDateOptions): string => {
    if (showMinutes === false && showSeconds === true) {
      throw Error('Minutes cannot be disabled, when seconds are enabled');
    }

    let remainingMinutes: Date;
    let hours: number;
    let minutes: number;
    let pad: (value: number) => string;

    if (padWithZeros) {
      pad = (value: number): string => String(value).padStart(2, '0');
    } else {
      pad = (value: number): string => String(value);
    }

    const interval = {
      start: parseISO(startDate),
      end: parseISO(endDate),
    };

    const sign = compareAsc(interval.start, interval.end);

    hours = Math.abs(differenceInHours(interval.start, interval.end));

    if (!showMinutes) {
      hours = Math.abs(differenceInHours(interval.start, interval.end));
    }

    if (!showSeconds) {
      remainingMinutes = sub(interval.start, { hours: sign * hours });
      minutes = Math.abs(differenceInMinutes(remainingMinutes, interval.end));
    } else {
      remainingMinutes = sub(interval.start, { hours: sign * hours });
      minutes = Math.abs(differenceInMinutes(remainingMinutes, interval.end));
    }

    const remainingSeconds = sub(remainingMinutes, { minutes: sign * minutes });
    const seconds = Math.abs(differenceInSeconds(remainingSeconds, interval.end));

    ({ hours, minutes } = DateTime.roundTime({
      hours,
      minutes,
      seconds,
      isRoundSeconds: !showSeconds,
      isRoundMinutes: !showMinutes,
    }));

    if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
      throw Error('Unable to calculate duration. Are startDate and endDate parsable ISO 8601 dates?');
    }

    let formattedDuration = `${pad(hours)}`;

    if (showMinutes) {
      formattedDuration = `${formattedDuration}:${pad(minutes)}`;
    }

    if (showSeconds) {
      formattedDuration = `${formattedDuration}:${pad(seconds)}`;
    }

    if (sign > 0) {
      formattedDuration = `-${formattedDuration}`;
    }

    return formattedDuration;
  };

  public static getCalendarWeekByDate = (date: Date): number => getISOWeek(date);

  public static getDayOfWeek = ({ date, weekStartsOnMonday = true }: GetDayOfWeekOptions): number => {
    // Default start day of the week is Sunday (0), but it can be changed to Monday (1)
    const weekStartsOn = weekStartsOnMonday ? 1 : 0;
    const firstDayOfWeek = startOfWeek(new Date(date), { weekStartsOn });

    return differenceInCalendarDays(new Date(date), firstDayOfWeek);
  };

  public static getDateByDayOfWeek = ({ weekday, hours, minutes, timezoneType }: GetDateByDayOfWeek): Date => {
    if (!Number.isInteger(weekday)) {
      throw Error('Argument "weekday" needs to be an integer.');
    }

    if (!Number.isInteger(hours)) {
      throw Error('Argument "hours" needs to be an integer.');
    }

    if (!Number.isInteger(minutes)) {
      throw Error('Argument "minutes" needs to be an integer.');
    }

    if (!Object.values(TimezoneType).includes(timezoneType)) {
      throw Error('Argument "timezoneType" needs to be either "utc" or "local"');
    }

    // NOTE: moment is replaced by dayjs
    // Since Moment's `startOf('isoWeek')` always returns the start of the week in local time and there is no way to get the
    // start of the week of a UTC DateTime we have to trick Moment a bit:
    //
    // * We create a moment with set it to be in local time using local(), this is the default anyway, but we are
    //   stating it again for readability
    // * We use startOf('isoWeek') which always returns the start of the week in local time
    // * We use `utc(true)` with the `keepLocalTime` flag set to`true`. This does two things
    // ** Sets the timezone of the moment to be UTC
    // ** It does so without changing the actual time values. Effectively shifting the described DateTime by the inverse
    //    of the UTC offset
    // ** The resulting date is the start of the week in UTC time
    //
    // See https://momentjs.com/docs/#/manipulating/utc/
    const firstDayOfWeekMoment =
      timezoneType === TimezoneType.LOCAL
        ? dayjs().local().startOf('isoWeek')
        : dayjs().local().startOf('isoWeek').utc(true);

    const momentInitializedWithTimezoneType =
      timezoneType === TimezoneType.LOCAL ? dayjs(firstDayOfWeekMoment).local() : dayjs(firstDayOfWeekMoment).utc();

    const date = momentInitializedWithTimezoneType
      .add(weekday - 1, 'd')
      .hour(hours)
      .minute(minutes)
      .second(0)
      .millisecond(0)
      .toDate();

    return date;
  };

  public static isUsing12HourClock = (locale: string): boolean => {
    const amPmStrings = ['AM', 'PM', 'μ.μ.', 'π.μ.'];
    const hourString = new Intl.DateTimeFormat(locale, { hour: 'numeric' }).format();

    return amPmStrings.some((amPmString: string): boolean => hourString.includes(`${amPmString}`));
  };

  public static roundTime = ({
    hours,
    minutes,
    seconds,
    isRoundSeconds = false,
    isRoundMinutes = false,
  }: RoundTime): { hours: number; minutes: number } => {
    const MINUTES_IN_HOUR = 60;
    let tempMinutes = minutes;
    let tempHours = hours;

    if (isRoundSeconds) {
      tempMinutes += seconds >= 30 ? 1 : 0;
      if (tempMinutes >= MINUTES_IN_HOUR) {
        tempHours += 1;
      }
      tempMinutes %= MINUTES_IN_HOUR;
    }
    if (isRoundMinutes) {
      if (tempMinutes >= 30) {
        tempHours += 1;
      }
      tempMinutes = 0;
    }

    return { hours: tempHours, minutes: tempMinutes };
  };
}

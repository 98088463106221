import { TableProps as AntdTableProps, Table as AntdTable } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import classnames from 'classnames';
import isNaN from 'lodash-es/isNaN';
import isNil from 'lodash-es/isNil';
import * as React from 'react';
import { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash-es/debounce';
import noop from 'lodash-es/noop';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { InfiniteScrollSpin } from '../InfiniteScrollSpin/InfiniteScrollSpin';
import { IPaginationProps, Pagination } from './NewPagination/Pagination';
import {
  StyledInfiniteScroll,
  StyledInfiniteScrollWrapper,
  StyledTable,
  TABLE_FIXED_HEADER_DEFAULT_HEIGHT,
} from './Table.styles';
import { TableScrollButtons } from './TableScrollButtons.tsx/TableScrollButtons';
import { Optional } from 'lib/types/Optional';
import { InfiniteScrollConstants } from 'config/constants';

const DEBOUNCE_DELAY_CALCULATE_SCROLLABILITY = 50;

type TableProps = Omit<AntdTableProps<any>, 'pagination' | 'footer'> & {
  pagination?: Omit<IPaginationProps, 'additionalComponents'>;
  footerComponents?: React.ReactNode;
  infiniteScroll?: {
    id: string;
    height?: number;
    next: () => void;
    isLoadingMoreData: Optional<boolean>;
    nextPaginationToken: string | null;
  };
  showScrollButtons?: boolean;
};

export type ColumnsTypeForCustomizableTable<TColumnId, RecordType = unknown> = (
  | (ColumnGroupType<RecordType> & {
      columnId: TColumnId;
      columnLabel: string;
      customizable?: boolean;
    })
  | (ColumnType<RecordType> & {
      columnId: TColumnId;
      columnLabel: string;
      customizable?: boolean;
    })
)[];

export const Table = (props: TableProps): JSX.Element => {
  const {
    className,
    pagination,
    footerComponents = null,
    expandable,
    columns,
    showSorterTooltip = false,
    dataSource = [],
    infiniteScroll,
    scroll,
    showScrollButtons,
    ...rest
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const infiniteScrollRef = useRef<InfiniteScroll>(null);
  const isExpandable = !isNil(expandable?.rowExpandable);
  const tableClassName = classnames('table', className, { 'table--expandable': isExpandable });
  const isInfiniteScrollTable = !isNil(infiniteScroll);
  let effectiveExpandable = expandable;
  let effectiveColumns = columns;

  const [canBeScrolledToTheLeft, setCanBeScrolledToTheLeft] = useState(false);
  const [canBeScrolledToTheRight, setCanBeScrolledToTheRight] = useState(false);

  if (isExpandable) {
    effectiveColumns = [...(columns ?? []), AntdTable.EXPAND_COLUMN];
    effectiveExpandable = {
      ...(expandable ?? {}),
      expandIcon: ({ expanded, onExpand, record }): React.ReactNode =>
        expandable?.rowExpandable?.(record) && (
          <div className="table__expansion-cell">
            <div
              className={classnames('table__expansion-icon', { 'table__expansion-icon--expanded': expanded })}
              onClick={(e): void => onExpand(record, e)}
            >
              <SvgIcon name="arrowDown" />
            </div>
          </div>
        ),
    };
  }

  const calculateScrollability = ({
    scrollWidth,
    clientWidth,
    scrollLeft,
  }: {
    scrollWidth: number;
    clientWidth: number;
    scrollLeft: number;
  }): void => {
    const scrollableWidth = scrollWidth - clientWidth;
    setCanBeScrolledToTheRight(scrollableWidth !== 0 && scrollLeft < scrollableWidth);
    setCanBeScrolledToTheLeft(scrollableWidth !== 0 && scrollLeft > 0);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleInfiniteTableLayoutUpdate = useCallback(
    debounce((): void => {
      if (infiniteScrollRef.current) {
        const scrollingElement = (infiniteScrollRef.current as InfiniteScroll | null)?.getScrollableTarget();
        if (isNil(scrollingElement)) return;

        calculateScrollability(scrollingElement);
      }
    }, DEBOUNCE_DELAY_CALCULATE_SCROLLABILITY),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleFiniteTableLayoutUpdate = useCallback(
    debounce((): void => {
      if (tableRef.current) {
        const scrollingElement = tableRef.current.querySelector('.ant-table-body');

        if (!isNil(scrollingElement)) {
          calculateScrollability(scrollingElement);
        }
      }
    }, DEBOUNCE_DELAY_CALCULATE_SCROLLABILITY),
    []
  );

  const handlePageChange = (newPage: number): void => {
    if (pagination?.onPageChange) {
      pagination?.onPageChange(newPage);
    }
  };

  const getFooterFactory = (): (() => ReactNode) | undefined => {
    if (isNil(pagination) && isNil(footerComponents)) {
      return undefined;
    }

    if (isNil(pagination)) {
      return (): ReactNode => footerComponents;
    }

    // eslint-disable-next-line react/display-name
    return (): ReactNode => (
      <Pagination {...pagination} onPageChange={handlePageChange} additionalComponents={footerComponents} />
    );
  };
  const hasMore = infiniteScroll?.nextPaginationToken !== null && !rest.loading;

  const getScroll = (): AntdTableProps['scroll'] => {
    if (scroll && scroll.x && scroll.y) {
      return scroll;
    }

    if (scroll && scroll.x && !scroll.y) {
      return { ...scroll, y: TABLE_FIXED_HEADER_DEFAULT_HEIGHT };
    }

    if ((scroll && !scroll.x) || !scroll) {
      const columnsWidthList = columns?.map(column => column.width) ?? [];
      const totalWidth = columnsWidthList.reduce((acc, width) => Number(acc) + (Number(width) ?? 0), 0);

      if (isNaN(totalWidth)) {
        const queryColumnsWidthList = tableRef.current?.querySelectorAll('.ant-table-thead th');

        let totalQueryWidth = 0;
        queryColumnsWidthList?.forEach(column => {
          totalQueryWidth += column.clientWidth - 1;
        });

        return {
          x: totalQueryWidth,
          y: scroll?.y ? scroll.y : TABLE_FIXED_HEADER_DEFAULT_HEIGHT,
        };
      }

      return {
        x: totalWidth,
        y: scroll?.y ? scroll.y : TABLE_FIXED_HEADER_DEFAULT_HEIGHT,
      };
    }

    return scroll;
  };

  useLayoutEffect(() => {
    if (isInfiniteScrollTable && !isNil(wrapperRef.current)) {
      const observedElement = wrapperRef.current?.querySelector('.table__infinite-scroll');
      const resizeObserver = new ResizeObserver(debouncedHandleInfiniteTableLayoutUpdate);

      if (!isNil(observedElement)) {
        resizeObserver.observe(observedElement);

        return () => resizeObserver.unobserve(observedElement);
      }
    }

    if (!isInfiniteScrollTable && tableRef.current) {
      const observedElement = tableRef.current?.querySelector('.ant-table-body');
      const resizeObserver = new ResizeObserver(debouncedHandleFiniteTableLayoutUpdate);

      if (!isNil(observedElement)) {
        resizeObserver.observe(observedElement);

        return () => resizeObserver.unobserve(observedElement);
      }
    }

    return noop;
  }, [debouncedHandleInfiniteTableLayoutUpdate, debouncedHandleFiniteTableLayoutUpdate, isInfiniteScrollTable]);

  useLayoutEffect(() => {
    if (isInfiniteScrollTable) {
      debouncedHandleInfiniteTableLayoutUpdate();
    } else if (tableRef.current) {
      debouncedHandleFiniteTableLayoutUpdate();
    }
  }, [
    canBeScrolledToTheLeft,
    canBeScrolledToTheRight,
    isInfiniteScrollTable,
    props.loading,
    props.infiniteScroll?.isLoadingMoreData,
    debouncedHandleFiniteTableLayoutUpdate,
    debouncedHandleInfiniteTableLayoutUpdate,
  ]);

  if (!isNil(infiniteScroll)) {
    return (
      <StyledInfiniteScrollWrapper className="table__infinite-scroll-wrapper" ref={wrapperRef}>
        <StyledInfiniteScroll
          className="table__infinite-scroll"
          id={infiniteScroll.id}
          height={infiniteScroll.height || InfiniteScrollConstants.DEFAULT_HEIGHT}
        >
          <InfiniteScroll
            ref={infiniteScrollRef}
            dataLength={dataSource.length}
            next={infiniteScroll.next}
            hasMore={hasMore}
            loader={infiniteScroll.isLoadingMoreData ? <InfiniteScrollSpin /> : null}
            scrollableTarget={infiniteScroll.id}
            onScroll={debouncedHandleInfiniteTableLayoutUpdate}
          >
            <StyledTable className={tableClassName}>
              <div ref={tableRef}>
                <AntdTable
                  pagination={false}
                  showSorterTooltip={showSorterTooltip}
                  {...rest}
                  footer={getFooterFactory()}
                  expandable={effectiveExpandable}
                  columns={effectiveColumns}
                  dataSource={dataSource}
                  // not define scroll y for infinite scroll, otherwise the infinite scroll not working
                  scroll={{
                    ...getScroll(),
                    y: undefined,
                  }}
                />
              </div>
            </StyledTable>
          </InfiniteScroll>
        </StyledInfiniteScroll>
        {showScrollButtons && (
          <TableScrollButtons
            canBeScrolledToTheLeft={canBeScrolledToTheLeft}
            canBeScrolledToTheRight={canBeScrolledToTheRight}
            isInfiniteScrollTable={isInfiniteScrollTable}
            wrapperRef={wrapperRef}
          />
        )}
      </StyledInfiniteScrollWrapper>
    );
  }

  return (
    <>
      <StyledTable className={tableClassName} isFixedHeader={!!getScroll()} ref={wrapperRef}>
        <div ref={tableRef}>
          <AntdTable
            pagination={false}
            showSorterTooltip={showSorterTooltip}
            {...rest}
            footer={getFooterFactory()}
            expandable={effectiveExpandable}
            columns={effectiveColumns}
            dataSource={dataSource}
            scroll={getScroll()}
            onScroll={debouncedHandleFiniteTableLayoutUpdate}
          />
        </div>
      </StyledTable>
      {showScrollButtons && (
        <TableScrollButtons
          canBeScrolledToTheLeft={canBeScrolledToTheLeft}
          canBeScrolledToTheRight={canBeScrolledToTheRight}
          isInfiniteScrollTable={isInfiniteScrollTable}
          wrapperRef={wrapperRef}
        />
      )}
    </>
  );
};

import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Col, Flex, Row } from 'antd';
import { Odometer } from '../Odometer/Odometer';
import { StyledShortInfo } from './ShortInfo.styles';
import { Optional } from 'lib/types/Optional';

export interface ShortInfoProps {
  className?: string;
  icon: ReactElement;
  title: string;
  value: number;
  unit?: Optional<string>;
}

export const ShortInfo = ({ className, icon, title, value, unit }: ShortInfoProps): JSX.Element => (
  <StyledShortInfo className={classnames('short-info', className)} key={title}>
    <Flex className="short-info__container" vertical>
      <div className="short-info__icon">
        <div>{icon}</div>
      </div>
      <Flex className="short-info__content" justify="space-between" vertical>
        <p title={title} className="short-info__title">
          {title}
        </p>
        <Row gutter={[4, 4]}>
          <Col>
            <Odometer value={value.toString()} />
          </Col>
          <Col>
            <p className="short-info__unit">{unit}</p>
          </Col>
        </Row>
      </Flex>
    </Flex>
  </StyledShortInfo>
);

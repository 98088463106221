import { createGlobalStyle } from 'styled-components';

export const PopConfirmGlobalStyles = createGlobalStyle`
  .ant-popconfirm {
    .ant-popover-inner {
      padding: ${(props): string => props.theme.spacing.lg};
      border-radius: ${(props): string => props.theme.borderRadiuses.lg};
    }
    
    .ant-popconfirm-message-icon {
      margin-right: ${(props): string => props.theme.spacing.sm};
      
      svg {
        height: 24px;
        width: 24px;
      }
    }
    
    .ant-popconfirm-title {
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
    }  
    
    .ant-popconfirm-message {
      .ant-popconfirm-description {
        margin-top: ${(props): string => props.theme.spacing.xs};
        margin-bottom: ${(props): string => props.theme.spacing.xs};
        ${(props): string => props.theme.fontStyles.redesign.base.normal};
      }  
    }
    
    .popconfirm__paragraph {
      margin-bottom: ${(props): string => props.theme.spacing.base};
    }

    .checkbox {
      ${(props): string => props.theme.fontStyles.redesign.base.normal};
      line-height: ${(props): string => props.theme.lineHeights.redesign.lg};
    }

    .popconfirm__checkbox-label {
      // Fix font baseline
      margin-top: 2px;
      margin-bottom: -2px;
    }
    
    .ant-btn-default {
      width: fit-content;
      height: 40px;
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
    }
    
    .ant-btn-primary {
      width: fit-content;
      height: 40px;
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
    }
  }
`;

import { Input as AntdInput } from 'antd';
import { forwardRef } from 'react';
import { Input, InputProps } from '../Input/Input';

export const AutoCompleteInput = forwardRef<typeof AntdInput, InputProps>((props, ref) => (
  <Input forwardRef={ref} {...props}>
    {props.children}
  </Input>
));

AutoCompleteInput.displayName = 'AutoCompleteInput';

import { useMemo } from 'react';
import { LegacyTextButton } from '../LegacyButton/LegacyTextButton/LegacyTextButton';
import { StyledChevronBar } from './ChevronBar.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { getMonthsByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';

interface ChevronBarProps {
  startDate: string | null;
  endDate: string | null;
  titleArray: string[];
  titleIndex: number;
  changeTitleIndex: (value: number) => void;
}

export const ChevronBar = ({ startDate, endDate, titleIndex, changeTitleIndex }: ChevronBarProps): JSX.Element => {
  const titleArray = useMemo(
    () => (startDate && endDate ? getMonthsByPeriod(startDate, endDate) : []),
    [startDate, endDate]
  );
  const isShowArrowRightBtn = useMemo(() => titleIndex !== titleArray.length - 1, [titleIndex, titleArray.length]);

  const isShowArrowLeftBtn = useMemo(() => titleIndex !== 0, [titleIndex]);

  const onLeftArrowClick = (): void => {
    if (!isShowArrowLeftBtn) {
      return;
    }
    changeTitleIndex(titleIndex - 1);
  };

  const onRightArrowClick = (): void => {
    if (!isShowArrowRightBtn) {
      return;
    }
    changeTitleIndex(titleIndex + 1);
  };

  return (
    <StyledChevronBar>
      <LegacyTextButton
        size="s"
        onClick={onLeftArrowClick}
        className={isShowArrowLeftBtn ? 'chevron-bar__arrow' : 'chevron-bar__arrow-hidden'}
        icon={<SvgIcon className="chevron-bar__arrow-left" name="arrow" />}
      />
      <span className="chevron-bar__title">{titleArray[titleIndex]}</span>
      <LegacyTextButton
        size="s"
        onClick={onRightArrowClick}
        className={isShowArrowRightBtn ? 'chevron-bar__arrow' : 'chevron-bar__arrow-hidden'}
        icon={<SvgIcon className="chevron-bar__arrow-right" name="arrow" />}
      />
    </StyledChevronBar>
  );
};

import styled from 'styled-components';
import { Select } from 'antd';

export const StyledPaginationSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 24px;
    ${(props): string => props.theme.fontStyles.caption.news}
    display: flex;
    align-items: center;
    border-radius: 0;
    background-color: transparent;
    border-color: ${(props): string => props.theme.colors.white};
    box-shadow: none;
    padding: 0 19px 0 11px;

    .ant-select-selection-item {
      line-height: ${(props): string => props.theme.lineHeights.caption};
      padding: 5px 8px;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    box-shadow: none;
  }

  .ant-select-dropdown {
    left: 10.546875px !important;
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    .ant-select-item-option {
      font-family: ${(props): string => props.theme.fonts.captionSmall};
      font-size: ${(props): string => props.theme.fontSizes.caption};
      line-height: ${(props): string => props.theme.lineHeights.caption};
      padding: 5px 8px;
    }

    .ant-select-item-option-active {
      &:not(.ant-select-item-option-disabled),
      &:hover,
      &:active,
      &:focus {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${(props): string => props.theme.colors.white};
    }
  }

  .ant-select-arrow {
    right: 4px;
    svg {
      transform: rotate(90deg);
    }
  }
`;

export const StyledOption = styled(Select.Option)`
  // TBD
`;

export const StyledOptGroup = styled(Select.OptGroup)`
  // TBD
`;

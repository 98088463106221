import { CSSProperties } from 'react';
import { StyledOdometer } from './Odometer.styles';
import { MAXIMUM_ODOMETER_DIGIT } from 'config/constants';

export const Odometer = ({ value }: { value: string }): JSX.Element => {
  const [digitsBeforeDecimal, digitsAfterDecimal] = value.split('.');
  const odometerDigitsStyles = (digit: string, index: number, data: string): CSSProperties => ({
    marginTop: `calc(-${digit}em)`,
    transition: `1000ms all`,
    transitionDelay: `${(data.concat('').split('').length - index) * 20}ms`,
  });

  return (
    <StyledOdometer className="odometer-wrapper">
      {digitsBeforeDecimal.split('').map((val: string, index: number) => (
        <div key={index} className="odometer-digits" style={odometerDigitsStyles(val, index, digitsBeforeDecimal)}>
          {[...Array(MAXIMUM_ODOMETER_DIGIT)].map((_, i) => (
            <div className="odometer-digit" data-val={i} key={i}>
              {i}
            </div>
          ))}
        </div>
      ))}
      {digitsAfterDecimal && (
        <>
          <span>.</span>
          {digitsAfterDecimal.split('').map((val: string, index: number) => (
            <div key={index} className="odometer-digits" style={odometerDigitsStyles(val, index, digitsAfterDecimal)}>
              {[...Array(MAXIMUM_ODOMETER_DIGIT)].map((_, i) => (
                <div className="odometer-digit" data-val={i} key={i}>
                  {i}
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </StyledOdometer>
  );
};

export class StringUtils {
  public static generateUserInitials = (fullName: string): string => {
    const hasTokens = fullName.indexOf(' ') !== -1;
    const userInitials =
      fullName.substring(0, hasTokens ? 1 : 2) + (hasTokens ? fullName.charAt(fullName.lastIndexOf(' ') + 1) : '');

    return userInitials.toUpperCase();
  };

  public static toKebapCase = (s: string): string =>
    s
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();
}

import classnames from 'classnames';
import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { StyledMessageList } from './MessageList.styles';
import { MessageListContent } from './MessageListContent';
import { IMessage } from './MessageList.types';

const MESSAGE_LIST_PAGE_SIZE = 3;

export interface MessageListProps<T> {
  title: string;
  messages: IMessage<T>[];
  listButtonViewRenderer?: (originalEntities: T[]) => React.ReactElement;
  messageButtonViewRenderer?: (originalEntity: T) => React.ReactElement;
  modals?: React.ReactElement;
  emptyView?: React.ReactElement;
  isInitialLoad: boolean;
  isLoadingMore: boolean;
  hasNextPage: boolean;
  totalCount: number;
  loadMore: () => void;
  activeMessageId?: string;
}

export const MessageList = <T,>({
  title,
  messages,
  listButtonViewRenderer,
  messageButtonViewRenderer,
  modals,
  emptyView,
  isInitialLoad,
  isLoadingMore,
  hasNextPage,
  loadMore,
  totalCount,
  activeMessageId,
}: MessageListProps<T>): JSX.Element => {
  const isLoading = isInitialLoad || isLoadingMore;
  const hasMessages = messages.length > 0;

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: totalCount <= MESSAGE_LIST_PAGE_SIZE || messages.length >= totalCount || !hasNextPage,
  });

  return (
    <StyledMessageList className={classnames('message-list')}>
      <div className="message-list__header">
        <h3 tabIndex={0} className="message-list__title">
          {title}
        </h3>
        {listButtonViewRenderer && (
          <div className="message-list__buttons">
            {listButtonViewRenderer(messages.map(message => message.originalEntity))}
          </div>
        )}
      </div>
      <div className={classnames('message-list__body', { 'message-list__body--empty': !hasMessages })}>
        <MessageListContent
          messages={messages}
          messageButtonViewRenderer={messageButtonViewRenderer}
          emptyView={emptyView}
          isInitialLoad={isInitialLoad}
          isLoadingMore={isLoadingMore}
          sentryRef={sentryRef}
          activeMessageId={activeMessageId}
        />
      </div>
      {modals}
    </StyledMessageList>
  );
};

import styled from 'styled-components';

export const StyledLegacyCheckboxLayoutGroup = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    margin: 4px 0;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

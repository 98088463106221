import { Input } from 'antd';
import styled from 'styled-components';

export const StyledLegacyInput = styled(Input)`
  background-color: ${(props): string => props.theme.colors.white};
  border-radius: 0;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props): string => props.theme.colors.darkGrey};
  padding: 12px 16px;

  ::placeholder {
    color: ${(props): string => props.theme.colors.darkGrey};
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  &.ant-input,
  &.ant-input-affix-wrapper > input.ant-input {
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  :hover {
    background-color: ${(props): string => props.theme.colors.brightGrey};
    border-color: ${(props): string => props.theme.colors.darkGrey};
    border-right-width: 2px !important;

    input {
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.semanticError};
  }

  &.ant-input-affix-wrapper-disabled,
  &.ant-input-affix-wrapper-disabled:hover {
    border-color: ${(props): string => props.theme.colors.brightGrey};
    background-color: ${(props): string => props.theme.colors.white};
    color: ${(props): string => props.theme.colors.brightGrey};
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }

  :focus-within,
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within {
    border-color: ${(props): string => props.theme.colors.focusBorder};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;

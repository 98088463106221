import { DropdownProps } from 'antd';
import classnames from 'classnames';
import { StyledDropdown } from './Dropdown.styles';

export const Dropdown = (props: DropdownProps): JSX.Element => {
  const { className, children, ...rest } = props;
  const DropdownClassName = classnames('dropdown', className);

  return (
    <>
      <StyledDropdown
        className={DropdownClassName}
        {...rest}
        getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      >
        {children}
      </StyledDropdown>
    </>
  );
};

import { Card } from 'antd';
import styled from 'styled-components';

export const StyledMessageList = styled(Card)`
  font-family: ${(props): string => props.theme.fonts.p1News};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1News};
  color: ${(props): string => props.theme.colors.black};

  .message-list__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 9px;
  }

  .message-list__title {
    flex: 1;
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 0;
  }

  .message-list__buttons {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    column-gap: 5px;
  }

  .message-list__create-button {
    width: 18px;
    height: 18px;
    color: ${(props): string => props.theme.colors.black};
    cursor: pointer;
  }

  .message-list__body {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-y: auto;
  }

  .message-list__body--empty {
    justify-content: center;
    align-items: center;
  }

  .message-list__message {
    border-top: 1px solid ${(props): string => props.theme.colors.brightGrey};
  }

  .message-list__initial-load-indicator {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .message-list__load-more-indicator {
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 24px;
  }

  .message-list__empty-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  .message-list__empty-icon {
    width: 40px;
    height: 50px;
  }

  .message-list__empty-text {
    ${(props): string => props.theme.fontStyles.p1.medium};
  }
`;

import styled from 'styled-components';

export const StyledSortableColumnTitle = styled.div`
  &.table-head--with-icon {
    display: flex;
    align-items: center;

    .table-head__icon {
      margin-left: 15px;
    }
  }
`;

import { CheckboxProps as AntdCheckboxProps } from 'antd';
import classNames from 'classnames';
import { StyledCheckbox } from './Checkbox.styles';
import { CheckboxLayoutGroup } from './CheckboxLayoutGroup/CheckboxLayoutGroup';

type CheckboxProps = AntdCheckboxProps;
export const Checkbox = ({ className, ...props }: CheckboxProps): JSX.Element => {
  const effectiveClassName = classNames('checkbox', className);

  return <StyledCheckbox className={effectiveClassName} {...props} />;
};

Checkbox.LayoutGroup = CheckboxLayoutGroup;

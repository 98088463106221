import classnames from 'classnames';
import { SegmentedProps as AntdSegmentedProps } from 'antd';
import { StyledSegmented } from './Segmented.styles';

type SegmentedProps<T> = AntdSegmentedProps<T>;

export const Segmented = <T,>(props: SegmentedProps<T>): JSX.Element => {
  const { className, ...rest } = props;
  const segmentedClassName = classnames(className, 'segmented');

  // @ts-ignore
  return <StyledSegmented {...rest} className={segmentedClassName} />;
};

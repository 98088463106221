import { ProgressProps as AntdProgressProps } from 'antd';
import classNames from 'classnames';
import { StyledStepsProgress } from './StepsProgress.styles';

// make steps required
type StepsProgressProps = Omit<AntdProgressProps, 'steps'> & {
  steps:
    | number
    | {
        count: number;
        gap: number;
      };
};

export const StepsProgress = ({ className, steps, ...props }: StepsProgressProps): JSX.Element => {
  const stepsProgressClassName = classNames(className, 'steps-progress');

  return <StyledStepsProgress className={stepsProgressClassName} {...props} steps={steps} />;
};

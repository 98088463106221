import React from 'react';
import classnames from 'classnames';
import { StyledTableScrollButtons } from './TableScrollButtons.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { ButtonBase } from 'lib/components/Button/ButtonBase';

const SCROLLABLE_DISTANCE_PER_STEP = 500;
const SCROLL_BEHAVIOUR = 'smooth';

export const TableScrollButtons = ({
  canBeScrolledToTheLeft,
  canBeScrolledToTheRight,
  wrapperRef,
  isInfiniteScrollTable = false,
}: {
  canBeScrolledToTheLeft: boolean;
  canBeScrolledToTheRight: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
  isInfiniteScrollTable?: boolean;
}): React.JSX.Element => {
  const tableBody = wrapperRef.current?.querySelector('.table .ant-table-body');
  const infiniteScrollElement = wrapperRef.current?.querySelector('.table__infinite-scroll');

  const handleScrollRight = (): void => {
    if (!isInfiniteScrollTable) {
      tableBody?.scrollBy({
        top: 0,
        left: SCROLLABLE_DISTANCE_PER_STEP,
        behavior: SCROLL_BEHAVIOUR,
      });
    } else {
      infiniteScrollElement?.scrollBy({
        top: 0,
        left: SCROLLABLE_DISTANCE_PER_STEP,
        behavior: SCROLL_BEHAVIOUR,
      });
    }
  };

  const handleScrollLeft = (): void => {
    if (!isInfiniteScrollTable) {
      tableBody?.scrollBy({
        top: 0,
        left: -SCROLLABLE_DISTANCE_PER_STEP,
        behavior: SCROLL_BEHAVIOUR,
      });
    } else {
      infiniteScrollElement?.scrollBy({
        top: 0,
        left: -SCROLLABLE_DISTANCE_PER_STEP,
        behavior: SCROLL_BEHAVIOUR,
      });
    }
  };

  return (
    <StyledTableScrollButtons
      className={classnames({
        'table-scroll-buttons--infinite-table': isInfiniteScrollTable,
      })}
      justify="space-between"
    >
      <ButtonBase
        className={classnames('table-scroll-buttons__button-left', {
          'table-scroll-buttons__button-left--hidden': !canBeScrolledToTheLeft,
        })}
        icon={<SvgIcon name="chevronLeft" />}
        onClick={handleScrollLeft}
      />

      <ButtonBase
        className={classnames('table-scroll-buttons__button-right', {
          'table-scroll-buttons__button-right--hidden': !canBeScrolledToTheRight,
        })}
        icon={<SvgIcon name="chevronRight" />}
        onClick={handleScrollRight}
      />
    </StyledTableScrollButtons>
  );
};

import { Row } from 'antd';
import styled from 'styled-components';

export const StyledTableScrollButtons = styled(Row)`
  position: absolute;
  top: 43px;
  width: 100%;

  .table-scroll-buttons__button-left,
  .table-scroll-buttons__button-right {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.1s linear, opacity 0.1s linear;
    width: 30px;
    height: 30px;
    border: none;
    background-color: ${(props): string => props.theme.colors.black};
    user-select: none;

    :hover {
      background-color: ${(props): string => props.theme.colors.black} !important;
    }

    :active {
      background-color: ${(props): string => props.theme.colors.black} !important;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .table-scroll-buttons__button-left {
    margin-left: -30px;
  }

  .table-scroll-buttons__button-right {
    margin-right: -30px;
  }

  .table-scroll-buttons__button-left--hidden,
  .table-scroll-buttons__button-right--hidden {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.1s linear, opacity 0.1s linear;
  }

  &.table-scroll-buttons--infinite-table {
    .table-scroll-buttons__button-left {
      margin-left: -29px;
    }

    .table-scroll-buttons__button-right {
      margin-right: -29px;
    }
  }
`;

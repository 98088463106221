import {
  IMimeType,
  MimeTypeAudio,
  MimeTypeCategory,
  MimeTypeDocument,
  MimeTypeImage,
  MimeTypeOther,
  MimeTypePresentation,
  MimeTypeSpreadsheet,
  MimeTypeVideo,
} from './MimeType.types';

// Using plural "MimeTypes" to avoid name conflict with deprecated web API
// See https://developer.mozilla.org/en-US/docs/Web/API/MimeType
export class MimeTypes {
  public static getFileExtension = (mimeType: string): string => {
    const fileExtension = MimeTypes.mimeTypeToFileExtensionMap[mimeType as IMimeType];

    if (fileExtension) {
      return fileExtension;
    }

    return MimeTypes.FILE_EXTENSION_FALLBACK;
  };

  public static getCategory = (mimeType: string): MimeTypeCategory => {
    if ((Object.values(MimeTypeAudio) as string[]).includes(mimeType)) {
      return MimeTypeCategory.AUDIO;
    }

    if ((Object.values(MimeTypeDocument) as string[]).includes(mimeType)) {
      return MimeTypeCategory.DOCUMENT;
    }

    if ((Object.values(MimeTypeImage) as string[]).includes(mimeType)) {
      return MimeTypeCategory.IMAGE;
    }

    if ((Object.values(MimeTypePresentation) as string[]).includes(mimeType)) {
      return MimeTypeCategory.PRESENTATION;
    }

    if ((Object.values(MimeTypeSpreadsheet) as string[]).includes(mimeType)) {
      return MimeTypeCategory.SPREADSHEET;
    }

    if ((Object.values(MimeTypeVideo) as string[]).includes(mimeType)) {
      return MimeTypeCategory.VIDEO;
    }

    if ((Object.values(MimeTypeOther) as string[]).includes(mimeType)) {
      return MimeTypeCategory.OTHER;
    }

    return MimeTypeCategory.OTHER;
  };

  private static FILE_EXTENSION_FALLBACK = 'file';

  private static mimeTypeToFileExtensionMap: Record<IMimeType, string> = {
    [MimeTypeAudio.AAC]: 'aac',
    [MimeTypeAudio['3GPP_AUDIO']]: '3gp',
    [MimeTypeAudio['3GPP2_AUDIO']]: '3g2',
    [MimeTypeAudio.MIDI]: 'midi',
    [MimeTypeAudio.MP3]: 'mp3',
    [MimeTypeAudio.OGG_AUDIO]: 'oga',
    [MimeTypeAudio.OPUS]: 'opus',
    [MimeTypeAudio.WAV]: 'wav',
    [MimeTypeAudio.WEBM_AUDIO]: 'weba',
    [MimeTypeDocument.DOC]: 'doc',
    [MimeTypeDocument.DOCX]: 'docx',
    [MimeTypeDocument.ODT]: 'odt',
    [MimeTypeDocument.PDF]: 'pdf',
    [MimeTypeDocument.RTF]: 'rtf',
    [MimeTypeDocument.TXT]: 'txt',
    [MimeTypeImage.BMP]: 'bmp',
    [MimeTypeImage.GIF]: 'gif',
    [MimeTypeImage.ICO]: 'ico',
    [MimeTypeImage.JPEG]: 'jpeg',
    [MimeTypeImage.PNG]: 'png',
    [MimeTypeImage.SVG]: 'svg',
    [MimeTypeImage.TIFF]: 'tiff',
    [MimeTypeImage.WEBP]: 'webp',
    [MimeTypePresentation.ODP]: 'odp',
    [MimeTypePresentation.PPT]: 'ppt',
    [MimeTypePresentation.PPTX]: 'pptx',
    [MimeTypeSpreadsheet.CSV]: 'csv',
    [MimeTypeSpreadsheet.ODS]: 'ods',
    [MimeTypeSpreadsheet.XLS]: 'xls',
    [MimeTypeSpreadsheet.XLSX]: 'xlsx',
    [MimeTypeVideo['3GPP_VIDEO']]: '3gp',
    [MimeTypeVideo['3GPP2_VIDEO']]: '3g2',
    [MimeTypeVideo.AVI]: 'avi',
    [MimeTypeVideo.MP4]: 'mp4',
    [MimeTypeVideo.MPEG]: 'mpeg',
    [MimeTypeVideo.OGG_VIDEO]: 'ogv',
    [MimeTypeVideo.WEBM_VIDEO]: 'webm',
    [MimeTypeOther['7ZIP']]: '7z',
    [MimeTypeOther.BINARY]: 'bin',
    [MimeTypeOther.BZIP]: 'bz',
    [MimeTypeOther.BZIP2]: 'bz2',
    [MimeTypeOther.CSS]: 'css',
    [MimeTypeOther.EOT]: 'eot',
    [MimeTypeOther.EPUB]: 'epub',
    [MimeTypeOther.GZIP]: 'gz',
    [MimeTypeOther.HTML]: 'html',
    [MimeTypeOther.ICAL]: 'ical',
    [MimeTypeOther.JS]: 'js',
    [MimeTypeOther.JSON]: 'json',
    [MimeTypeOther.OTF]: 'otf',
    [MimeTypeOther.RAR]: 'rar',
    [MimeTypeOther.TAR]: 'tar',
    [MimeTypeOther.TTF]: 'ttf',
    [MimeTypeOther.VSD]: 'vsd',
    [MimeTypeOther.WOFF]: 'woff',
    [MimeTypeOther.WOFF2]: 'woff2',
    [MimeTypeOther.XML]: 'xml',
    [MimeTypeOther.ZIP]: 'zip',
  };
}

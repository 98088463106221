import { useTranslation } from 'react-i18next';
import { StyledInfoTooltip } from './InfoTooltip.styles';

export const InfoTooltip = ({ content }: { content: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledInfoTooltip className="info-tooltip">
      <div className="info-tooltip__description">{t(content)}</div>
    </StyledInfoTooltip>
  );
};

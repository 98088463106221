import { RangePickerProps as AntdRangePickerProps } from 'antd/lib/date-picker';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { DATE_FORMAT_PATTERN } from '../../utils/date-handling/DateTime.types';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { StyledRangePicker } from './RangePicker.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';

type RangePickerProps = AntdRangePickerProps;

export const RangePicker = (props: RangePickerProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { className, allowClear = false, ...rest } = props;
  const rangePickerClassName = classnames('range-picker', className);

  return (
    <StyledRangePicker
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      className={rangePickerClassName}
      popupAlign={{ offset: [0, -12] }}
      format={(date): string =>
        DateTime.formatDateByLocale(i18n.language, date.toISOString(), DATE_FORMAT_PATTERN.DATE)
      }
      separator={<SvgIcon name="smallArrowRight" />}
      allowClear={allowClear}
      {...rest}
    />
  );
};

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ColumnType, SortOrder } from 'antd/lib/table/interface';
import { ReactNode } from 'react';
import { StyledSortableColumnTitle } from './SortableColumnTitle.styles';

type SortColumns<T> = {
  column: ColumnType<T>;
  order: SortOrder;
}[];

export const SortableColumnTitle = <T,>(
  title: string,
  isSortingAndFilteringEnabled: boolean,
  key?: string,
  sortColumns?: SortColumns<T>
): ReactNode => {
  if (!isSortingAndFilteringEnabled) {
    return <div className="table-head--with-icon">{title}</div>;
  }

  const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
  let SortIcon = null;
  if (sortedColumn) {
    SortIcon = sortedColumn.order === 'ascend' ? <CaretUpOutlined /> : <CaretDownOutlined />;
  }

  return (
    <StyledSortableColumnTitle className="sortable-column-title table-head--with-icon">
      {title}
      <span className="table-head__icon">{SortIcon}</span>
    </StyledSortableColumnTitle>
  );
};

import styled from 'styled-components';

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px;

  .message__header {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: 100%;
  }

  .message__icon {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  .message__header-text {
    width: 100%;
    flex-grow: 1;
    max-width: calc(100% - 32px - 12px - 15px - 12px);
  }

  .message__title {
    ${(props): string => props.theme.fontStyles.p1.bold};
    ${(props): string => props.theme.mixins.truncateText}
    margin-bottom: 2px;
  }

  .message__subtitle {
    ${(props): string => props.theme.fontStyles.caption.news};
    ${(props): string => props.theme.mixins.truncateText}
  }

  .message__header-buttons {
    display: none;
    flex: 0 0 auto;
    color: ${(props): string => props.theme.colors.black};
    gap: 8px;
  }

  &:hover {
    ${(props): string => props.theme.mixins.dropShadow}

    .message__header-buttons {
      display: flex;
    }
  }

  &.message-list__message--active,
  &.message-list__message--active:active {
    border: 1px solid ${(props): string => props.theme.colors.black};
  }
`;

import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { DefaultOptionType, BaseOptionType, SelectProps as AntSelectProps } from 'antd/lib/select';
import classnames from 'classnames';
import { isArray, isBoolean } from 'lodash-es';
import isFunction from 'lodash-es/isFunction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../Button/SecondaryButton/SecondaryButton';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { StyledOptGroup, StyledOption, StyledSelect } from './Select.styles';

type SelectProps = AntSelectProps & {
  className?: string;
  selectedOptionsOnTop?: boolean;
  dropdownVisibleState?: boolean;
  onReset?: () => void;
};

const selectedOptionsOnTopCompareFn =
  (selectedOptions: string[]) =>
  <OptionType extends BaseOptionType | DefaultOptionType>(optionA: OptionType, optionB: OptionType): number => {
    if (!selectedOptions) return 0;

    let selectOptionsBuffer = selectedOptions;

    if (!isArray(selectOptionsBuffer)) {
      selectOptionsBuffer = [selectOptionsBuffer];
    }

    const aIncluded = selectOptionsBuffer?.includes(optionA.value);
    const bIncluded = selectOptionsBuffer?.includes(optionB.value);

    if (aIncluded && bIncluded) {
      return 0;
    }
    if (!aIncluded && !bIncluded) {
      return 0;
    }
    if (aIncluded) {
      return -1;
    }
    return 1;
  };

export function Select(props: SelectProps): React.JSX.Element {
  const { t } = useTranslation();
  const {
    onChange,
    onReset,
    mode,
    dropdownVisibleState,
    popupMatchSelectWidth = false,
    dropdownRender,
    selectedOptionsOnTop,
    showSearch,
    ...rest
  } = props;
  // make effectiveSelectedOptionsOnTop true as default
  const effectiveSelectedOptionsOnTop = props.selectedOptionsOnTop ?? true;
  const effectiveShowSearch = props.showSearch ?? true;
  const effectiveOptionFilterProp = props.optionFilterProp ?? 'label';

  if (effectiveShowSearch && !isBoolean(dropdownVisibleState)) {
    throw Error(
      'A boolean needs to be passed to prop dropdownVisibleState ' +
        'when search is enabled. Either pass in a valid value or deactivate search'
    );
  }

  const getEffectiveSuffixIcon = (): React.ReactElement => {
    if (effectiveShowSearch && dropdownVisibleState) {
      return <SearchOutlined />;
    }

    return <SvgIcon className="select__suffix-icon" name="newArrowDown" />;
  };
  const resetValue: string | string[] = mode === 'multiple' || mode === 'tags' ? [] : '';

  const handleReset = (): void => {
    if (isFunction(onChange)) {
      onChange(resetValue, { label: t('common.all'), value: undefined });
    }

    if (isFunction(onReset)) {
      onReset();
    }
  };

  const getDropdownRender = (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ): React.JSX.Element => (
    <>
      {menu}
      <div className="select__button-bar">
        <SecondaryButton icon={<ReloadOutlined />} iconPosition="end" size="s" fullWidth={true} onClick={handleReset}>
          {t('common.reset')}
        </SecondaryButton>
      </div>
    </>
  );

  return (
    <StyledSelect
      {...rest}
      className={classnames('select', props.className)}
      mode={mode}
      onChange={onChange}
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      dropdownAlign={{ offset: [0, -2] }}
      showSearch={effectiveShowSearch}
      suffixIcon={getEffectiveSuffixIcon()}
      optionFilterProp={effectiveOptionFilterProp}
      filterSort={effectiveSelectedOptionsOnTop ? selectedOptionsOnTopCompareFn(props.value as string[]) : undefined}
      maxTagCount="responsive"
      dropdownRender={dropdownRender || getDropdownRender}
      optionRender={({ label }): React.JSX.Element => (
        <>
          <span className="select__label">{label}</span>
          <span className="select__checkmark">
            <SvgIcon name="checkmark" />
          </span>
        </>
      )}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  );
}

Select.Option = StyledOption;
Select.OptGroup = StyledOptGroup;

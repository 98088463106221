import React from 'react';
import { Spin } from 'antd';
import { StyledLoadingPage } from './LoadingPage.styles';

export interface LoadingPageProps {
  className?: string;
}

export const LoadingPage = ({ className }: LoadingPageProps): JSX.Element => (
  <StyledLoadingPage className={className}>
    <Spin size="large" />
  </StyledLoadingPage>
);

import { StyledLoadingIndicator } from './LoadingIndicator.styles';

interface SpinnerLoadingProps {
  size?: 'small';
}

export const LoadingIndicator = ({ size }: SpinnerLoadingProps): JSX.Element => (
  <StyledLoadingIndicator className="loading-indicator" size={size}>
    <div className="loading-indicator__icon" />
  </StyledLoadingIndicator>
);

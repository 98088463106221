import styled from 'styled-components';
import { BarProgress } from '../BarProgress/BarProgress';

export const StyledStepsProgress = styled(BarProgress)`
  .ant-progress-steps-outer {
    width: 100%;
  }

  .ant-progress-steps-item {
    flex: 1;
    height: 6px !important;
  }
`;

import { isNonEmptyEnvVar } from './isNonEmptyEnvVar';

export const isEnvVarEnabled = (envVarValue: string | undefined): boolean => {
  const valuesAcceptedAsTrue = ['true', '1', 'yes', 'on', 'enabled'];

  if (!isNonEmptyEnvVar(envVarValue)) {
    return false;
  }

  return valuesAcceptedAsTrue.includes(envVarValue);
};

import styled from 'styled-components';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
  .drawer__close-button {
    position: absolute;
    top: 62px;
    left: 0;
    margin-left: -83px;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: ${(props): string => props.theme.spacing.base};

    width: 130px;
    height: 36px;
    transform: rotate(-90deg);
    padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
    border-top-left-radius: ${(props): string => props.theme.borderRadiuses.lg};
    border-top-right-radius: ${(props): string => props.theme.borderRadiuses.lg};

    background-color: ${(props): string => props.theme.colors.brightGrey};
    ${(props): string => props.theme.fontStyles.redesign.base.strong}
    cursor: pointer;
  }

  .drawer__close-icon {
    width: 12px;
    height: 12px;

    svg {
      vertical-align: 12px;

      path {
        fill: ${(props): string => props.theme.colors.black};
      }
    }
  }

  .ant-drawer-body {
    padding: ${(props): string => props.theme.spacing.xl};
  }

  .ant-drawer-footer {
    border-top: none;
  }
`;

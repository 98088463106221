import styled from 'styled-components';
import { ButtonBase } from '../ButtonBase';

export const StyledTextButton = styled(ButtonBase)`
  font-family: ${(props): string => props.theme.fonts.p1Medium};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  border: none;
  background-color: transparent;
  color: ${(props): string => props.theme.colors.black};
  box-shadow: none;

  :hover,
  :focus,
  :active {
    background-color: transparent;
    color: ${(props): string => props.theme.colors.black};
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    background-color: transparent;
  }

  &.button--size-s {
    height: 32px;
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
    padding: 6px ${(props): string => props.theme.spacing.base} 4px;
  }

  &.button--size-m {
    width: fit-content;
    height: 40px;
    ${(props): string => props.theme.fontStyles.redesign.lg.strong};
    padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
  }

  &.button--size-l {
    width: fit-content;
    height: ${(props): string => props.theme.spacing.xxl};
    ${(props): string => props.theme.fontStyles.redesign.lg.strong};
    padding: ${(props): string => props.theme.spacing.sm} ${(props): string => props.theme.spacing.md};
  }

  &.button--full-width {
    width: 100%;
  }
`;

import classNames from 'classnames';
import React from 'react';
import { StyledLegacyCheckboxLayoutGroup } from './LegacyCheckboxLayoutGroup.styles';

export interface LegacyCheckboxLayoutGroupProps {
  className?: string;
  children: React.ReactNode;
}

/**
 * @name LegacyCheckboxLayoutGroup
 * @description This is a simple layout component to apply styles to a group of checkboxes.
 * This does not implement any of the functionality of the ant design <Checkbox.Group> component.
 * @example
 * <LegacyCheckbox.LayoutGroup>
 *   <Form.Item name="a" valuePropName="checked" noStyle>
 *      <LegacyCheckbox>Checkbox A</LegacyCheckbox>
 *   </Form.Item>
 *   <Form.Item name="b" valuePropName="checked" noStyle>
 *     <LegacyCheckbox>Checkbox B</LegacyCheckbox>
 *   </Form.Item>
 * </LegacyCheckbox.LayoutGroup>
 * @see https://ant.design/components/checkbox#checkbox-group
 */
export const LegacyCheckboxLayoutGroup = ({ className, children }: LegacyCheckboxLayoutGroupProps): JSX.Element => (
  <StyledLegacyCheckboxLayoutGroup className={classNames('legacy-checkbox-layout-group', className)}>
    {children}
  </StyledLegacyCheckboxLayoutGroup>
);

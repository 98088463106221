import { Empty, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook';
import classnames from 'classnames';
import { Message } from './Message/Message';
import { IMessage } from './MessageList.types';

export interface MessageListContentProps<T> {
  messages: IMessage<T>[];
  messageButtonViewRenderer?: (originalEntity: T) => React.ReactElement;
  isInitialLoad: boolean;
  isLoadingMore: boolean;
  emptyView?: React.ReactElement;
  sentryRef: IntersectionObserverHookRefCallback;
  activeMessageId?: string;
}

export const MessageListContent = <T,>({
  messages,
  messageButtonViewRenderer,
  isInitialLoad,
  isLoadingMore,
  emptyView,
  sentryRef,
  activeMessageId,
}: MessageListContentProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const hasMessages = messages.length > 0;
  const getMessageClassName = (messageId: string): string =>
    classnames('message-list__message', {
      'message-list__message--active': messageId === activeMessageId,
    });

  const effectiveEmptyView = emptyView ?? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.noData')} />
  );

  if (isInitialLoad) {
    return (
      <>
        <div className="message-list__initial-load-indicator">
          <Spin size="large" />
        </div>
      </>
    );
  }

  if (hasMessages) {
    return (
      <>
        {messages.map((message: IMessage<T>) => (
          <Message
            className={getMessageClassName(message.id)}
            key={message.id}
            message={message}
            buttonViewRenderer={messageButtonViewRenderer}
          />
        ))}
        {isLoadingMore && (
          <div className="message-list__load-more-indicator">
            <Spin size="small" />
          </div>
        )}
        <div ref={sentryRef} className="message-list__scroll-sentry" />
      </>
    );
  }

  // Empty list
  return (
    <>
      <div className="message-list__empty-indicator">{effectiveEmptyView}</div>
    </>
  );
};

import { createGlobalStyle } from 'styled-components';

export const DrawerGlobalStyles = createGlobalStyle`
  .ant-drawer {
    z-index: 999;

    .ant-drawer-content-wrapper {
      max-width: 1528px;
    }

    .ant-drawer-mask {
      background: unset;
    }
  }

  .ant-drawer-content-wrapper {
    border-left: 8px solid ${(props): string => props.theme.colors.brightGrey};
  }
`;

import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    align-items: center;
  }

  .ant-radio {
    top: initial;

    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border: 2px solid ${(props): string => props.theme.colors.black};

      &::after {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        background-color: ${(props): string => props.theme.colors.black};
      }
    }

    // animation
    &::after {
      border-color: ${(props): string => props.theme.colors.black};
    }
  }

  // onFocus
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgb(88 88 88 / 12%);
  }

  // disabled
  .ant-radio.ant-radio-disabled {
    .ant-radio-inner {
      background-color: transparent;
      border-color: ${(props): string => props.theme.colors.grey};

      &::after {
        background-color: transparent;
      }
    }
  }
`;

export const StyledGroup = styled(Radio.Group)`
  // TBD
`;

export const StyledButton = styled(Radio.Button)`
  // TBD
`;

import { DrawerProps as AntdDrawerProps } from 'antd';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { DrawerGlobalStyles } from './Drawer.global.styles';
import { StyledDrawer } from './Drawer.styles';

export interface DrawerProps extends Omit<AntdDrawerProps, 'size'> {
  className?: string;
  size?: 'extra-small' | 'small' | 'medium' | 'large';
}

export const Drawer = (props: DrawerProps): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { className, width, onClose, destroyOnClose = true, closeIcon = null, children, size, ...rest } = props;
  const drawerClassNames = classnames('drawer', className);

  if (!!width && !!size) {
    throw new Error(
      // eslint-disable-next-line max-len
      'Set drawer width either by picking a preset via the size prop or enter a numerical value via the width prop, not both.'
    );
  }

  const effectiveWidth = useMemo((): DrawerProps['width'] => {
    if (size === 'large') return theme.constants.drawer.width.lg;
    if (size === 'medium') return theme.constants.drawer.width.md;
    if (size === 'small') return theme.constants.drawer.width.sm;
    if (size === 'extra-small') return theme.constants.drawer.width.xs;

    return width;
  }, [
    size,
    theme.constants.drawer.width.xs,
    theme.constants.drawer.width.md,
    theme.constants.drawer.width.sm,
    theme.constants.drawer.width.lg,
    width,
  ]);

  return (
    <>
      <StyledDrawer
        className={drawerClassNames}
        width={effectiveWidth}
        destroyOnClose={destroyOnClose}
        closeIcon={closeIcon}
        onClose={onClose}
        {...rest}
      >
        <div className="drawer__close-button" onClick={onClose}>
          <div className="drawer__close-button-label">{t('common.close')}</div>
          <SvgIcon className="drawer__close-icon" name="xmark" />
        </div>
        {children}
      </StyledDrawer>
      <DrawerGlobalStyles />
    </>
  );
};

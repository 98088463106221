import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import { StyledNoStyleButton } from './NoStyleButton.styles';

type NoStyleButtonProps = HTMLAttributes<HTMLDivElement>;

export const NoStyleButton = ({ className, ...props }: NoStyleButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'button', 'no-style-button');

  return <StyledNoStyleButton role="button" className={buttonClassName} {...props} />;
};

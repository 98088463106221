import styled from 'styled-components';
import { ButtonBase } from '../ButtonBase';

export const StyledSecondaryButton = styled(ButtonBase)`
  background-color: ${(props): string => props.theme.colors.white};
  color: ${(props): string => props.theme.colors.black};
  border: 1px solid ${(props): string => props.theme.colors.black};
  border-radius: 0;
  align-items: center;

  // TODO: should use design token, add more specificity to override
  &.ant-btn.button-base {
    :hover,
    :focus,
    :active {
      border-color: ${(props): string => props.theme.colors.darkGrey};
      background-color: ${(props): string => props.theme.colors.white};
      color: ${(props): string => props.theme.colors.black};
    }

    :hover {
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }

    :focus {
      border: 1px solid ${(props): string => props.theme.colors.focusBorder};
    }

    :active {
      background-color: ${(props): string => props.theme.colors.black};
      color: ${(props): string => props.theme.colors.white};
    }

    :disabled,
    :disabled:hover {
      border-color: ${(props): string => props.theme.colors.brightGrey};
      background-color: ${(props): string => props.theme.colors.white};
      color: ${(props): string => props.theme.colors.brightGrey};
    }

    &.button--size-s {
      height: 32px;
      ${(props): string => props.theme.fontStyles.redesign.base.normal};
      padding: 6px ${(props): string => props.theme.spacing.base} 4px;
    }

    &.button--size-m {
      width: fit-content;
      height: 40px;
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
    }

    &.button--size-l {
      width: fit-content;
      height: ${(props): string => props.theme.spacing.xxl};
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
      padding: ${(props): string => props.theme.spacing.sm} ${(props): string => props.theme.spacing.md};
    }

    &.button--full-width {
      width: 100%;
    }
  }
`;

import { TooltipProps as AntdTooltipProps } from 'antd';
import classnames from 'classnames';
import { StyledTooltip } from './Tooltip.styles';
import { TooltipGlobalStyles } from './Tooltip.global.styles';

type TooltipProps = AntdTooltipProps;

export const Tooltip = (props: TooltipProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const tooltipClassName = classnames('tooltip', className);

  return (
    <>
      <TooltipGlobalStyles />
      <StyledTooltip arrow={{ pointAtCenter: true }} className={tooltipClassName} {...rest}>
        {children}
      </StyledTooltip>
    </>
  );
};

import { InputProps as AntdInputProps, Input as AntdInput } from 'antd';
import classnames from 'classnames';
import { StyledLegacyInput } from './LegacyInput.styles';

export type LegacyInputProps = AntdInputProps & {
  forwardRef?: React.ForwardedRef<typeof AntdInput>;
};

export const LegacyInput = (props: LegacyInputProps): JSX.Element => {
  const { children, className, forwardRef, ...rest } = props;
  const inputClassName = classnames('legacy-input', className);

  return (
    // @ts-ignore
    <StyledLegacyInput ref={forwardRef} className={inputClassName} {...rest}>
      {children}
    </StyledLegacyInput>
  );
};

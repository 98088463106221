import React from 'react';
import { ModalProps as AntdModalProps } from 'antd';
import { StyledModal } from './Modal.styles';

interface ModalProps extends AntdModalProps {
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = (props): JSX.Element => {
  const { children, className, ...rest } = props;
  const modalClassName = [className, 'modal'].join(' ');
  // By default, destroy modals on close so cleanup of useEffect hooks are triggered
  const destroyOnClose = props.destroyOnClose ?? true;

  return (
    <StyledModal className={modalClassName} centered closable={false} destroyOnClose={destroyOnClose} {...rest}>
      {children}
    </StyledModal>
  );
};

import styled from 'styled-components';
import { ProgressBase } from '../ProgressBase';

export const StyledBarProgress = styled(ProgressBase)`
  .ant-progress-outer {
    width: 100%;
    height: 6px !important;
  }

  .ant-progress-inner {
    margin-bottom: 3px;
    background-color: ${(props): string => props.theme.colors.brightGrey};
    height: 6px !important;

    .ant-progress-bg {
      background-color: ${(props): string => props.theme.colors.semanticInfo};
    }
  }
`;

import styled from 'styled-components';
import { Select } from 'antd';

/* eslint-disable max-len */

export const StyledSelect = styled(Select)`
  &.ant-select {
    ${(props): string => props.theme.fontStyles.redesign.base.normal}
    caret-color: transparent;
    width: 100%;
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
    .ant-select-selector {
      border-color: ${(props): string => props.theme.colors.semanticError};
    }
  }

  .ant-select-item,
  .ant-select-selection-search-input {
    color: ${(props): string => props.theme.colors.black};
    ${(props): string => props.theme.fontStyles.redesign.base.normal}
  }

  &.ant-select.ant-select-single,
  &.ant-select.ant-select-multiple {
    .ant-select-selector {
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: ${(props): string => props.theme.spacing.xs} ${(props): string => props.theme.spacing.base};
      padding-right: ${(props): string => props.theme.spacing.xl};
      border-radius: 0;

      :hover {
        background-color: ${(props): string => props.theme.colors.brightGrey};
      }

      :focus {
        border-color: ${(props): string => props.theme.colors.black};
      }

      :active {
        color: ${(props): string => props.theme.colors.darkGrey};
      }

      .ant-select-selection-overflow {
        position: absolute;
        height: 32px;
        left: ${(props): string => props.theme.spacing.base};
        width: 100%;
      }
    }

    &.ant-select.ant-select-show-search {
      .ant-select-selection-overflow {
        width: 85%;
      }
    }

    &.ant-select.ant-select-single {
      .ant-select-selection-item {
        display: flex;
        padding-inline-end: 0;
        margin-top: 1px;

        ${(props): string => props.theme.fontStyles.redesign.base.normal}
        color: ${(props): string => props.theme.colors.black};
      }

      .ant-select-selector .ant-select-selection-search {
        inset-inline-start: 16px;
      }
    }

    .ant-select-dropdown .ant-select-item-option-content {
      width: 100%;
      overflow: unset;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &.ant-select-open {
      caret-color: unset;

      .ant-select-selector {
        color: ${(props): string => props.theme.colors.darkGrey};
        background-color: ${(props): string => props.theme.colors.white};
      }

      .ant-select-selection-placeholder {
        display: none;
      }
    }

    &.ant-select-disabled,
    &.ant-select-disabled:hover {
      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
        color: ${(props): string => props.theme.colors.darkGrey};
        border-color: ${(props): string => props.theme.colors.darkGrey};
      }

      .ant-select-arrow {
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${(props): string => props.theme.colors.black};
      }
    }

    .ant-select-arrow {
      color: ${(props): string => props.theme.colors.darkGrey};
      font-size: 1rem;
      width: fit-content;
      margin-top: -5px;
    }
  }

  &.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector,
  &.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: ${(props): string => props.theme.colors.black};
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    box-shadow: none;
  }

  .ant-select-dropdown {
    min-width: 300px;
    border-radius: 0;
    padding: 0;
    border: 1px solid ${(props): string => props.theme.colors.black};

    .ant-select-item-option {
      height: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      padding: 5px ${(props): string => props.theme.spacing.sm};
      border-bottom: 1px solid ${(props): string => props.theme.colors.brightGrey};
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      ${(props): string => props.theme.fontStyles.redesign.base.strong}
      background-color: ${(props): string => props.theme.colors.white};

      .ant-select-item-option-state {
        color: ${(props): string => props.theme.colors.black};
      }

      .select__checkmark {
        display: block;
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }

  .ant-select-selection-placeholder {
    ${(props): string => props.theme.fontStyles.redesign.base.normal}
    color: ${(props): string => props.theme.colors.black};
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    top: 55%;
    left: 16px;
    color: ${(props): string => props.theme.colors.black};
  }

  &.ant-select-single .ant-select-selection-placeholder {
    padding: 6px 18px 6px 0px;
    color: ${(props): string => props.theme.colors.black};
  }

  &.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-overflow
    > .ant-select-selection-overflow-item-suffix:first-child
    .ant-select-selection-search-input {
    margin: 0 -7px;
    color: ${(props): string => props.theme.colors.black};
    ${(props): string => props.theme.fontStyles.redesign.base.normal}
  }

  .select__button-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${(props): string => props.theme.spacing.sm};
    gap: ${(props): string => props.theme.spacing.xs};
  }

  .select__label {
    flex: 1 1;
    margin-top: 3px;
    ${(props): string => props.theme.mixins.truncateText}
  }

  .select__checkmark {
    display: none;
    flex: 0 0;
    width: 24px;
    height: 24px;
    margin-left: auto;
    padding-left: ${(props): string => props.theme.spacing.sm};
    padding-right: ${(props): string => props.theme.spacing.lg};

    i,
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .ant-select-item-option-state {
    display: none;
  }

  .select__dropdown-label {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .select__dropdown-label-text {
    margin-top: 1px;
  }

  .select__counter {
    line-height: 20px;
    margin-top: -1px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;

    color: ${(props): string => props.theme.colors.white};
    background-color: ${(props): string => props.theme.colors.black};
  }

  .select__suffix-icon {
    width: 16px;
    height: 16px;

    i,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .ant-select-selection-overflow-item {
    span.ant-select-selection-item {
      border-radius: ${(props): string => props.theme.borderRadiuses.sm};
      border: 1px solid rgba(0, 0, 0, 0.06);
    }
  }

  .ant-select-selection-item {
    ${(props): string => props.theme.fontStyles.redesign.base.normal}
    height: 22px;

    .ant-select-selection-item-remove {
      svg {
        fill: rgba(0, 0, 0, 0.45);
      }
    }
  }
`;

export const StyledOption = styled(Select.Option)`
  // TBD
`;

export const StyledOptGroup = styled(Select.OptGroup)`
  // TBD
`;

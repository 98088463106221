import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  font-family: ${(props): string => props.theme.fonts.p1News};
  font-size: ${(props): string => props.theme.fontSizes.p1};
  line-height: ${(props): string => props.theme.lineHeights.p1};
  font-weight: ${(props): number => props.theme.fontWeights.p1News};
  border-radius: 0;

  &.ant-card-bordered {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  }

  .ant-card-head {
    background-color: ${(props): string => props.theme.colors.white};
    font-family: ${(props): string => props.theme.fonts.p1Bold};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Bold};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    border-radius: 0;

    .ant-card-head-wrapper {
      height: 50px;
    }
  }

  .ant-card-actions {
    border-color: transparent;
    padding: 23px 31px;

    > li {
      margin: 0;
    }
  }
`;

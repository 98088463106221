import React from 'react';
import { Space } from 'antd';
import { StyledTags } from './Tags.styles';
import { Tag } from 'lib/components/Tag/Tag';

export interface TagsProps {
  tags: string[];
}
export const Tags = ({ tags }: TagsProps): JSX.Element => (
  <StyledTags>
    <Space size={[8, 8]} wrap>
      {tags.map((tag: string) => (
        <Tag key={`tag-${tag}`}>{tag}</Tag>
      ))}
    </Space>
  </StyledTags>
);

import styled from 'styled-components';
import { LegacyButtonBase } from '../LegacyButtonBase';

export const StyledLegacyPrimaryButton = styled(LegacyButtonBase)`
  font-family: ${(props): string => props.theme.fonts.buttonM};
  font-size: ${(props): string => props.theme.fontSizes.buttonM};
  line-height: ${(props): string => props.theme.lineHeights.buttonM};
  font-weight: ${(props): number => props.theme.fontWeights.buttonM};
  background-color: ${(props): string => props.theme.colors.primary};
  color: ${(props): string => props.theme.colors.black};
  border-radius: 0;
  border: 2px solid ${(props): string => props.theme.colors.primary};

  // TODO: should use design token, add more specificity to override
  &.ant-btn.legacy-button-base {
    :hover,
    :focus,
    :active {
      background-color: ${(props): string => props.theme.colors.primary};
      color: ${(props): string => props.theme.colors.black};
      border-color: transparent;
    }

    :hover {
      background-color: ${(props): string => props.theme.colors.primaryHover};
    }

    :focus {
      border-color: ${(props): string => props.theme.colors.focusBorder};
    }

    :active {
      background-color: ${(props): string => props.theme.colors.black};
      color: ${(props): string => props.theme.colors.primary};
    }

    :disabled,
    :disabled:hover {
      border-color: ${(props): string => props.theme.colors.brightGrey};
      background-color: ${(props): string => props.theme.colors.brightGrey};
      color: ${(props): string => props.theme.colors.grey};
    }

    &.legacy-button__size-s {
      height: auto;
      font-size: ${(props): string => props.theme.fontSizes.p1};
      line-height: ${(props): string => props.theme.lineHeights.p1};
      padding: 6px 12px 5px;
    }

    &.legacy-button__size-m {
      width: fit-content;
      height: 44px;
      font-size: ${(props): string => props.theme.fontSizes.buttonM};
      line-height: ${(props): string => props.theme.lineHeights.buttonM};
      padding: 12px 16px;
    }
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { TextButton } from '../../Button/TextButton/TextButton';
import { PaginationSelect } from '../PaginationSelect/PaginationSelect';
import { StyledPagination } from './Pagination.styles';
import { PAGE_SIZE_OPTIONS } from 'config/constants';
import { ReactComponent as RightArrow } from 'lib/assets/images/rightArrow.svg';

export interface IPaginationProps {
  className?: string;
  page: number;
  pageSize: number;
  total: number;
  onPageChange?(page: number): void;
  onPageLimitChange?(limit: number): void;
  isLoading?: boolean;
  showPageSizeDropdown?: boolean;
  additionalComponents?: React.ReactNode;
}

export const Pagination = (props: IPaginationProps): JSX.Element => {
  const { t } = useTranslation();
  const showPageSizeDropdown = props.showPageSizeDropdown ?? true;
  const {
    className,
    page,
    pageSize,
    total,
    isLoading,
    additionalComponents = null,
    onPageChange,
    onPageLimitChange,
  } = props;
  const currentPage = page < 1 ? 1 : page;

  let from = 0;
  let to = 0;

  if (total > 0) {
    to = currentPage * pageSize;
    from = to - pageSize + 1;

    if (to > total) {
      to = total;
    }
  }

  const handlePageChange = (newPage: number): void => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handlePageLimitChange = (limit: number): void => {
    if (onPageLimitChange) {
      onPageLimitChange(limit);
    }
  };

  return (
    <StyledPagination className={classnames('pagination', className)}>
      <Row>
        <Col xs={24} sm={8} md={8} lg={8} className="pagination__limit-selection">
          {showPageSizeDropdown && (
            <>
              <span className="pagination__page-size">{t('tablePagination.itemsPerPage')}</span>:
              <PaginationSelect
                options={PAGE_SIZE_OPTIONS}
                value={pageSize}
                onChange={(limit: any): void => handlePageLimitChange(limit)}
              />
            </>
          )}
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} className="pagination__body-col">
          <div className="pagination__body">
            <TextButton
              size="s"
              onClick={(): void => handlePageChange(currentPage - 1)}
              className="pagination__prev-button"
              disabled={isLoading || currentPage === 1}
              icon={<RightArrow width={12} height={12} />}
            />
            <span className="pagination__items">
              {from}-{to} {t('tablePagination.of')} {total}
            </span>
            <TextButton
              size="s"
              onClick={(): void => handlePageChange(currentPage + 1)}
              className="pagination__next-button"
              disabled={isLoading || to === total}
              icon={<RightArrow width={12} height={12} />}
            />
          </div>
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} className="pagination__additional-components">
          {additionalComponents}
        </Col>
      </Row>
    </StyledPagination>
  );
};

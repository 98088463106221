import classnames from 'classnames';
import React from 'react';
import { useTheme } from 'styled-components';
import { ColorUtils } from '../../utils/colors/ColorUtils';
import { StyledStatusPill, StyledStatusPillProps } from './StatusPill.styles';

export interface StatusPillProps extends StyledStatusPillProps {
  className?: string;
  children: React.ReactNode;
  size?: 'large' | 'small';
}

export const StatusPill = ({ className, children, color, size = 'large' }: StatusPillProps): React.JSX.Element => {
  const theme = useTheme();
  const getEffectiveColor = (): string => {
    if (color === 'green') return ColorUtils.getCssVariableValue(theme.colors.green100);
    if (color === 'yellow') return ColorUtils.getCssVariableValue(theme.colors.karcherYellow100);
    if (color === 'red') return ColorUtils.getCssVariableValue(theme.colors.red100);
    if (color === 'blue') return ColorUtils.getCssVariableValue(theme.colors.blue100);
    if (color === 'gray') return ColorUtils.getCssVariableValue(theme.colors.gray100);

    return color;
  };

  return (
    <StyledStatusPill
      className={classnames(className, 'status-pill', `status-pill--size-${size}`)}
      color={getEffectiveColor()}
    >
      {children}
    </StyledStatusPill>
  );
};

import { Progress } from 'antd';
import styled from 'styled-components';

export const StyledProgressBase = styled(Progress)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .ant-progress-text {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
    width: fit-content;
    margin-left: 0px;
    word-break: break-word;
    margin-left: 12px;
  }
`;

/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

export const TooltipGlobalStyles = createGlobalStyle`
  .ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        ${(props): string => props.theme.fontStyles.redesign.base.normal};
        border-radius: ${(props): string => props.theme.borderRadiuses.base};
        background-color: ${(props): string => props.theme.colors.black};
        padding: 6px 8px;
        min-height: auto;
      }
    }
  }
`;

import { TableProps as AntdTableProps, Table as AntdTable } from 'antd';
import classnames from 'classnames';
import isNil from 'lodash-es/isNil';
import * as React from 'react';
import { ReactNode, useRef } from 'react';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { IPaginationProps, Pagination } from './Pagination/Pagination';
import { StyledLegacyTable } from './LegacyTable.styles';

type LegacyTableProps = Omit<AntdTableProps<any>, 'pagination' | 'footer'> & {
  pagination?: Omit<IPaginationProps, 'additionalComponents'>;
  footerComponents?: React.ReactNode;
};

export const LegacyTable = (props: LegacyTableProps): JSX.Element => {
  const { className, pagination, footerComponents, expandable, columns, ...rest } = props;
  const isExpandable = !isNil(expandable?.rowExpandable);
  const tableClassName = classnames('legacy-table', { 'legacy-table--expandable': isExpandable });
  const tableRef = useRef<HTMLDivElement>(null);
  let effectiveExpandable = expandable;
  let effectiveColumns = columns;

  if (isExpandable) {
    effectiveColumns = [...(columns ?? []), AntdTable.EXPAND_COLUMN];
    effectiveExpandable = {
      ...(expandable ?? {}),
      expandIcon: ({ expanded, onExpand, record }): React.ReactNode =>
        expandable?.rowExpandable?.(record) && (
          <div className="legacy-table__expansion-cell">
            <div
              className={classnames('legacy-table__expansion-icon', {
                'legacy-table__expansion-icon--expanded': expanded,
              })}
              onClick={(e): void => onExpand(record, e)}
            >
              <SvgIcon name="arrowDown" />
            </div>
          </div>
        ),
    };
  }

  const scrollToTop = (): void => {
    tableRef.current?.scrollIntoView({ block: 'start' });

    // Scroll up a bit more so sticky header doesn't overlap the top of the table
    const scrollContainer = document.querySelector('main.ant-layout-content');
    if (scrollContainer) {
      scrollContainer.scrollBy({
        top: -150,
      });
    }
  };

  const handlePageChange = (newPage: number): void => {
    if (pagination?.onPageChange) {
      pagination?.onPageChange(newPage);
    }

    scrollToTop();
  };

  const getFooterFactory = (): (() => ReactNode) => {
    if (isNil(pagination)) {
      return (): ReactNode => footerComponents;
    }

    // eslint-disable-next-line react/display-name
    return (): ReactNode => (
      <Pagination {...pagination} onPageChange={handlePageChange} additionalComponents={footerComponents} />
    );
  };

  return (
    <StyledLegacyTable className={tableClassName} isFixedHeader={!!rest.scroll}>
      <div ref={tableRef}>
        <AntdTable
          pagination={false}
          {...rest}
          footer={getFooterFactory()}
          expandable={effectiveExpandable}
          columns={effectiveColumns}
        />
      </div>
    </StyledLegacyTable>
  );
};

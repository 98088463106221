import React, { useState } from 'react';
import { UploadProps as AntdUploadProps, Upload as AntUpload, UploadFile, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { RcFile } from 'antd/es/upload';
import { Modal } from '../Modal/Modal';
import { StyledUpload } from './Upload.styles';
import { UploadGlobalStyles } from './Upload.global.styles';
import { FILE_UPLOAD_DEFAULT_MAX_FILE_SIZE, FILE_UPLOAD_MAX_NO_FILES } from 'config/constants';
import { MimeTypeCategory } from 'lib/utils/mime-type/MimeType.types';
import { MimeTypes } from 'lib/utils/mime-type/MimeTypes';

type UploadProps = Omit<AntdUploadProps, 'multiple' | 'maxCount' | 'fileList'> & {
  mode: 'multiple' | 'single';
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<unknown>[]>>;
  rules?: {
    maxFileSize?: number;
    fileTypeCats?: MimeTypeCategory[];
  };
  onFilesChange?: (files: UploadFile[]) => void;
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => resolve(reader.result as string);
    reader.onerror = (error): void => reject(error);
  });

export const Upload = (props: UploadProps): JSX.Element => {
  const { children, className, fileList, setFileList, rules, onFilesChange, ...rest } = props;
  const uploadClassName = classnames(props.mode === 'multiple' ? 'multi-upload' : 'single-upload', className);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const maxFileSize = rules?.maxFileSize || FILE_UPLOAD_DEFAULT_MAX_FILE_SIZE;
  const supportedFileTypeCats = rules?.fileTypeCats || [MimeTypeCategory.DOCUMENT, MimeTypeCategory.IMAGE];
  const modeParams =
    props.mode === 'multiple'
      ? { props: { multiple: true }, count: FILE_UPLOAD_MAX_NO_FILES }
      : { props: { maxCount: 1 }, count: 1 };

  const handleCancelPreview = (): void => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      const preview = await getBase64(file.originFileObj as RcFile);
      Object.assign(file, { preview });
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url?.substring(file.url?.lastIndexOf('/') || 0 + 1) || '');
  };

  // TODO: relocate toast under lib to reuse
  const openNotification = (description: string): void => {
    api.error({
      message: t('toasts.validationError.title'),
      description,
      placement: 'topRight',
    });
  };

  const handleChange: AntdUploadProps['onChange'] = ({ fileList: newFileList }): void => {
    const hasExceededMaxSizeFile = newFileList.some(file => Math.floor(file?.size || 0) >= maxFileSize);
    if (hasExceededMaxSizeFile) {
      openNotification(t('upload.errors.maxFileSize', { fileSize: Math.floor(maxFileSize / 1024 / 1024) }));
    }

    const hasUnsupportedFileType = newFileList.some(
      file => !supportedFileTypeCats.includes(MimeTypes.getCategory(file?.type || ''))
    );
    if (hasUnsupportedFileType) {
      openNotification(t('upload.errors.unsupportedFileType'));
    }

    const filteredNewFileList = newFileList
      .slice(0, modeParams.count)
      .filter(file => Math.floor(file?.size || 0) < maxFileSize)
      .filter(file => supportedFileTypeCats.includes(MimeTypes.getCategory(file?.type || '')));
    setFileList(filteredNewFileList);
    onFilesChange?.(filteredNewFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="upload__description">{t('upload.uploadDesc')}</div>
    </div>
  );

  return (
    <StyledUpload>
      <UploadGlobalStyles />
      {contextHolder}
      <AntUpload
        className={uploadClassName}
        listType="picture-card"
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={(): boolean => false} // return false to prevent built-in uploader
        {...modeParams.props}
        {...rest}
        fileList={fileList}
      >
        {fileList?.length >= modeParams.count ? null : uploadButton}
      </AntUpload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelPreview} closable={true}>
        <img alt="previewTitle" className="preview-image" src={previewImage} />
      </Modal>
    </StyledUpload>
  );
};

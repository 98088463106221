import { InputProps as AntdInputProps, Input as AntdInput } from 'antd';
import classnames from 'classnames';
import { StyledInput } from './Input.styles';

export type InputProps = AntdInputProps & {
  forwardRef?: React.ForwardedRef<typeof AntdInput>;
};

export const Input = (props: InputProps): JSX.Element => {
  const { children, className, forwardRef, ...rest } = props;
  const inputClassName = classnames('input', className);

  return (
    // @ts-ignore
    <StyledInput ref={forwardRef} className={inputClassName} {...rest}>
      {children}
    </StyledInput>
  );
};

import { RefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollToTopProps {
  containerRef: RefObject<HTMLDivElement>;
}

/**
 * Scroll container to top every time the route changes
 */
export const ScrollToTop = ({ containerRef }: ScrollToTopProps): null => {
  const location = useLocation();

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [containerRef, location.pathname]);

  return null;
};

import { TimePicker } from 'antd';
import styled from 'styled-components';
// TODO: wait design
export const StyledTimeRangePicker = styled(TimePicker.RangePicker)`
  width: 250px;
  height: 32px;
  border: 1px solid ${(props): string => props.theme.colors.darkGrey};
  border-radius: 0;
  text-transform: uppercase;

  &.ant-picker {
    box-shadow: none;

    &:hover,
    &:focus,
    &:active,
    &.ant-picker-focused {
      border-right-width: 1px !important;
    }

    &:hover {
      border-color: ${(props): string => props.theme.colors.darkGrey};
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }

    &.ant-picker-status-error {
      border-color: ${(props): string => props.theme.colors.semanticError};
    }

    .ant-picker-input {
      max-width: 70px;
      margin-bottom: -3px;

      > input {
        ${(props): string => props.theme.fontStyles.p1.medium};
      }
    }

    .ant-picker-range-separator {
      color: ${(props): string => props.theme.colors.grey};
      margin-bottom: -3px;
    }

    &.ant-picker-focused {
      width: 100%;
      .ant-picker-range-separator {
        color: ${(props): string => props.theme.colors.black};
      }
    }

    .ant-picker-suffix {
      width: 20px;
      right: 10px;
      position: absolute;
    }

    .ant-picker-active-bar {
      bottom: 8px;
      background-color: ${(props): string => props.theme.colors.black};
    }

    .ant-picker-dropdown {
      font-size: ${(props): string => props.theme.fontSizes.p1};
      top: 40px !important;
      background-color: ${(props): string => props.theme.colors.white};
      padding: 0;

      .ant-picker-range-arrow {
        display: none;
      }

      .ant-picker-time-panel {
        padding-top: 0px;
      }

      .ant-picker-panel-container {
        box-shadow: none;
        border: none;

        .ant-picker-panel-container {
          border-color: ${(props): string => props.theme.colors.black};
        }

        .ant-picker-panel-layout > div {
          width: 100%;
        }
      }

      .ant-picker-panel-container,
      .ant-picker-panels,
      .ant-picker-panel {
        width: 100%;
      }

      .ant-picker-content {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .ant-picker-range-separator {
        margin-right: 10px;
      }

      .ant-picker-time-range-wrapper {
        border: 1px solid ${(props): string => props.theme.colors.darkGrey};
      }

      .ant-picker-time-panel-column {
        overflow-y: auto;
        text-align: center;
        border-left: 1px solid ${(props): string => props.theme.colors.black};

        &:after {
          display: none;
        }

        .ant-picker-time-panel-cell {
          display: flex;
          justify-content: center;

          .ant-picker-time-panel-cell-inner {
            background-color: ${(props): string => props.theme.colors.white};
            color: ${(props): string => props.theme.colors.black};
            font-family: ${(props): string => props.theme.fonts.p1Medium};
            height: 24px;
            line-height: ${(props): string => props.theme.lineHeights.heading4};
            width: 40px;
            padding-left: 0;

            &:hover {
              background-color: ${(props): string => props.theme.colors.brightGrey};
              color: ${(props): string => props.theme.colors.black};
            }
          }
        }

        li.ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            background-color: ${(props): string => props.theme.colors.black};
            color: ${(props): string => props.theme.colors.white};
            font-family: ${(props): string => props.theme.fonts.p1Medium};
          }
        }
      }

      .ant-picker-footer {
        border-bottom: none;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ant-picker-ranges {
          padding-right: 5px;
        }

        .ant-picker-ok {
          .ant-btn-sm {
            font-family: ${(props): string => props.theme.fonts.buttonM};
            background-color: ${(props): string => props.theme.colors.primary};
            color: ${(props): string => props.theme.colors.black};
            font-size: ${(props): string => props.theme.fontSizes.p1};
            border: 2px solid ${(props): string => props.theme.colors.primary};
            border-radius: 0;
            height: auto;
            line-height: ${(props): string => props.theme.lineHeights.p1};
            padding: 6px 12px 5px;
            box-shadow: none;
          }
        }
      }
    }
  }
`;

import { Button, ButtonProps as AntdButtonProps } from 'antd';
import classnames from 'classnames';

interface IButtonBaseProps {
  size?: 's' | 'm' | '';
  fullWidth?: boolean;
}

export type ButtonBaseProps = Partial<Omit<AntdButtonProps, 'size'> & IButtonBaseProps>;

export const ButtonBase = (props: ButtonBaseProps): JSX.Element => {
  const { size = 's', fullWidth = false, className = '', iconPosition = 'end', ...rest } = props;
  const buttonClassName = classnames(className, 'button-base', `button--size-${size}`, {
    'button--full-width': fullWidth,
  });

  return <Button className={buttonClassName} iconPosition={iconPosition} {...rest} />;
};

import { DatePickerProps } from 'antd/lib/date-picker';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { DATE_FORMAT_PATTERN } from '../../utils/date-handling/DateTime.types';
import { StyledDatePicker } from './DatePicker.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';

export const DatePicker = (props: DatePickerProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { className, allowClear = false, ...rest } = props;
  const datePickerClassName = classnames('date-picker', className);

  return (
    <StyledDatePicker
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      className={datePickerClassName}
      popupAlign={{ offset: [0, -2] }}
      format={(date): string =>
        DateTime.formatDateByLocale(i18n.language, date.toISOString(), DATE_FORMAT_PATTERN.DATE)
      }
      allowClear={allowClear}
      {...rest}
    />
  );
};

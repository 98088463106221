import { RefObject } from 'react';
import upArrow from '../../assets/images/upArrow.svg';
import { StyledScrollToTop } from './ScrollToTopButton.styles';
import { LegacyTextButton } from 'lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';

export interface ScrollToTopButtonProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const ScrollToTopButton = ({ containerRef }: ScrollToTopButtonProps): JSX.Element => (
  <StyledScrollToTop className="scroll-to-top">
    <LegacyTextButton
      onClick={(): void => containerRef.current?.scrollIntoView({ behavior: 'smooth' })}
      className="scroll-to-top__button"
    >
      <img src={upArrow} alt="up arrow" />
    </LegacyTextButton>
  </StyledScrollToTop>
);

import { ProgressProps as AntdProgressProps } from 'antd';
import classnames from 'classnames';
import { StyledProgressBase } from './ProgressBase.styles';

type ProgressProps = AntdProgressProps;

export const ProgressBase = (props: ProgressProps): JSX.Element => {
  const { className, ...rest } = props;
  const ProgressClassName = classnames(className, 'progress-base');

  return <StyledProgressBase strokeLinecap="butt" className={ProgressClassName} {...rest} />;
};

import classnames from 'classnames';
import { LegacyButtonBaseProps } from '../LegacyButtonBase';
import { StyledLegacySecondaryButton } from './LegacySecondaryButton.styles';

type LegacyOutlinedButtonProps = LegacyButtonBaseProps;

export const LegacySecondaryButton = ({ className, ...props }: LegacyOutlinedButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'legacy-outlined-button');

  return <StyledLegacySecondaryButton className={buttonClassName} {...props} />;
};

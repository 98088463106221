import { SpinProps as AntdSpinProps } from 'antd';
import { StyledInfiniteScrollSpin } from './InfiniteScrollSpin.styles';

type InfiniteScrollSpinProps = AntdSpinProps;

export const InfiniteScrollSpin = (props: InfiniteScrollSpinProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const infiniteScrollSpinClassName = [className, 'infinite-scroll-spin'].join(' ');

  return <StyledInfiniteScrollSpin className={infiniteScrollSpinClassName} {...rest} />;
};

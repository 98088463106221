import styled from 'styled-components';

export const StyledScrollToTop = styled.div`
  display: flex;
  justify-content: flex-end;

  .scroll-to-top__button {
    font-size: 16px;
    margin: 14px 10px;
  }
`;

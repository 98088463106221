import { TagProps as AntdTagProps } from 'antd';
import { StyledTag } from './Tag.styles';

type TagProps = AntdTagProps;

export const Tag = (props: TagProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const tagClassName = [className, 'tag'].join(' ');

  return (
    <StyledTag bordered={false} className={tagClassName} {...rest}>
      <div className="tag__content">{children}</div>
    </StyledTag>
  );
};

import { RangePickerProps as AntdRangePickerProps } from 'antd/lib/date-picker';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { DATE_FORMAT_PATTERN } from '../../utils/date-handling/DateTime.types';
import { StyledLegacyRangePicker } from './LegacyRangePicker.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';

type LegacyRangePickerProps = AntdRangePickerProps;

export const LegacyRangePicker = (props: LegacyRangePickerProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { className, allowClear = false, ...rest } = props;
  const rangePickerClassName = classnames('legacy-range-picker', className);

  return (
    <StyledLegacyRangePicker
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      className={rangePickerClassName}
      popupAlign={{ offset: [0, -13] }}
      format={(date): string =>
        DateTime.formatDateByLocale(i18n.language, date.toISOString(), DATE_FORMAT_PATTERN.DATE)
      }
      separator={'-'}
      allowClear={allowClear}
      {...rest}
    />
  );
};

import styled from 'styled-components';

export const StyledLoadingIndicator = styled.div<{ size?: 'small' }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .loading-indicator__icon {
    display: flex;
    width: ${(props): string => (props.size === 'small' ? '20px' : '60px')};
    height: ${(props): string => (props.size === 'small' ? '20px' : '60px')};
    border-radius: 50%;
    border-top: ${(props): string => (props.size === 'small' ? '2px' : '4px')} solid
      ${(props): string => props.theme.colors.black};
    border-right: ${(props): string => (props.size === 'small' ? '2px' : '4px')} solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    &::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      width: ${(props): string => (props.size === 'small' ? '20px' : '60px')};
      height: ${(props): string => (props.size === 'small' ? '20px' : '60px')};
      border-radius: 50%;
      border-left: ${(props): string => (props.size === 'small' ? '2px' : '4px')} solid
        ${(props): string => props.theme.colors.primary};
      border-bottom: ${(props): string => (props.size === 'small' ? '2px' : '4px')} solid transparent;
      animation: rotation 0.5s linear infinite reverse;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import React from 'react';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { StyledPagination } from './Pagination.styles';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { ReactComponent as RightArrow } from 'lib/assets/images/rightArrow.svg';

export interface IPaginationProps {
  className?: string;
  page: number;
  pageSize: number;
  total: number;
  onPageChange?(page: number): void;
  onPageLimitChange?(limit: number): void;
  isLoading?: boolean;
  showPageSizeDropdown?: boolean;
  additionalComponents?: React.ReactNode;
}

export const Pagination = (props: IPaginationProps): JSX.Element => {
  const { className, page, pageSize, total, isLoading, onPageChange } = props;
  const currentPage = page < 1 ? 1 : page;

  let to = 0;

  if (total > 0) {
    to = currentPage * pageSize;

    if (to > total) {
      to = total;
    }
  }

  const handlePageChange = (newPage: number): void => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  return (
    <StyledPagination className={classnames('pagination', className)}>
      <Row justify="space-between">
        <Col className="pagination__body-col">
          <TextButton
            size="s"
            onClick={(): void => handlePageChange(currentPage - 1)}
            className="pagination__prev-button"
            disabled={isLoading || currentPage === 1}
            icon={<RightArrow width={12} height={12} />}
          />
        </Col>
        <Col className="pagination__body-col">
          <TextButton
            size="s"
            onClick={(): void => handlePageChange(currentPage + 1)}
            className="pagination__next-button"
            disabled={isLoading || to === total}
            icon={<RightArrow width={12} height={12} />}
          />
        </Col>
      </Row>
    </StyledPagination>
  );
};
